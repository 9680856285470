import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default class Validation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameError: '',
            passwordError: '',
            passwordVisible: false,
            errorMessage: '',
            snackbarOpen: false,
            alertSeverity: 'error',
        };
    }

    handleValidation = async () => {
        const email = document.getElementById('email-val').value;
        const code = document.getElementById('code-val').value
        if (code.length === 0 || email.length === 0) {
            this.throwError("Please fill in all fields");
        }

        const formData = new FormData();
        formData.append('email', email)
        formData.append('code', code);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/activate`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log(data);
            if (data.message === "User activated") {
                localStorage.removeItem('reg-email');
                this.throwError("User activated! Redirects to the login page...", true);
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            } else {
                this.throwError("Wrong validation code!");
            }
        } catch (error) {
            console.log(error);
            this.throwError("Invalid email or code");
        }
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpen: false });
    };

    throwError = (message, green = false) => {
        if (green) {
            this.setState({ alertSeverity: 'success' })
        } else {
            this.setState({ alertSeverity: 'error' })
        }
        this.setState({
            errorMessage: message,
            snackbarOpen: true,
        });
        setTimeout(() => {
            this.setState({ snackbarOpen: false });
        }, 3000);
        return;
    }

    render() {
        return (
            <Box sx={{
                height: 'calc(100vh - 64px)',
                position: 'relative',
                width: '100vw',
                transition: 'all 0.3s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
                component="div">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '1px solid black',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    padding: '60px 100px 80px 100px'
                }}
                    component="div"
                >
                    <h1>Validation</h1>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <TextField
                            required
                            id="email-val"
                            label="Email"
                            variant="outlined"
                            helperText="Your email"
                            defaultValue={localStorage.getItem('reg-email')}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            inputProps={{
                                maxLength: 30
                            }}
                        />
                        <TextField
                            required
                            id="code-val"
                            label="Code"
                            variant="outlined"
                            helperText="Your verification code"
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            inputProps={{
                                maxLength: 30
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    this.handleValidation();
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={this.handleValidation}
                            sx={{ width: '200px', marginBottom: '20px' }}
                        >
                            Validation
                        </Button>
                    </Box>
                </Box>

                <Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={this.handleCloseSnackbar} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

            </Box>
        )
    }
}
