import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageState: 'login',
            usernameError: '',
            passwordError: '',
            emailError: '',
            passwordVisible: false,
            errorMessage: '',
            snackbarOpen: false,
            alertSeverity: 'error',
            checked: false,
        };
    }

    handleChange = (event) => {
        this.setState({ checked: event.target.checked });
    }

    handleRegister = async () => {
        const username = document.getElementById('username-reg').value;
        const email = document.getElementById('email-reg').value;
        const password = document.getElementById('password-reg').value;
        const passwordAgain = document.getElementById('password-reg-again').value;
        if (username.length === 0 || password.length === 0 || email.length === 0 || passwordAgain.length === 0) {
            this.throwError('Please fill in all fields');
            return;
        }
        if (!email.endsWith('@shanghaitech.edu.cn') && !email.endsWith('@ucas.ac.cn')) {
            this.setState({ emailError: 'Only support email end with @shanghaitech.edu.cn or @ucas.ac.cn' });
            return;
        }
        if (password !== passwordAgain) {
            this.throwError('Passwords do not match');
            return;
        }

        if (username.length > 10 || password.length > 16) {
            if (username.length > 10) {
                this.setState({ usernameError: 'Username exceeds 10 characters' });
            } else {
                this.setState({ usernameError: '' });
            }

            if (password.length > 16) {
                this.setState({ passwordError: 'Password exceeds 16 characters' });
            } else {
                this.setState({ passwordError: '' });
            }
            return;
        }

        if (!this.state.checked) {
            this.throwError('Please agree to the User Agreement and Privacy Policy');
            return;
        }

        this.throwError("Code sending. Please wait...")

        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email)
        formData.append('password', password);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.message === 'Code sended') {
                localStorage.setItem('reg-email', email)
                this.throwError("Code sended! Redirects to the validation page...", true)
                setTimeout(() => {
                    window.location.href = '/validation';
                }, 3000);
            } else {
                this.throwError(data.message)
            }
        } catch (error) {
            console.log(error);
            this.throwError('Invalid username or password');
        }
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpen: false });
    };

    throwError = (message, green = false) => {
        if (green) {
            this.setState({ alertSeverity: 'success' });
        } else {
            this.setState({ alertSeverity: 'error' });
        }
        this.setState({
            errorMessage: message,
            snackbarOpen: true,
        });
        setTimeout(() => {
            this.setState({ snackbarOpen: false });
        }, 3000);
        return;
    }

    render() {
        return (
            <Box sx={{
                height: 'calc(100vh - 64px)',
                position: 'relative',
                width: '100vw',
                transition: 'all 0.3s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
                component="div">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '1px solid black',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    padding: '60px 100px 80px 100px'
                }}
                    component="div"
                >
                    <h1>Sign Up</h1>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <TextField
                            required
                            id="username-reg"
                            label="Username"
                            variant="outlined"
                            helperText={this.state.usernameError ? this.state.usernameError : 'Please enter your username'}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            inputProps={{
                                maxLength: 10
                            }}
                        />
                        <TextField
                            required
                            error={this.state.emailError ? true : false}
                            id="email-reg"
                            label="Email"
                            variant="outlined"
                            helperText={this.state.emailError ? this.state.emailError : `Only accept shanghaitech.edu.cn / ucas.ac.cn`}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            inputProps={{
                                maxLength: 30
                            }}
                        />
                        <TextField
                            required
                            id="password-reg"
                            label="Password"
                            variant="outlined"
                            helperText={this.state.passwordError ? this.state.passwordError : 'Please enter your password'}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            InputProps={{
                                maxLength: 16,
                                type: this.state.passwordVisible ? 'text' : 'password',
                                endAdornment: (
                                    <IconButton
                                        onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                                        sx={{ float: 'right' }}
                                        tabIndex={-1}
                                    >
                                        {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            required
                            id="password-reg-again"
                            label="Password Again"
                            variant="outlined"
                            helperText="Please enter your password again"
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            InputProps={{
                                maxLength: 16,
                                type: this.state.passwordVisible ? 'text' : 'password',
                                endAdornment: (
                                    <IconButton
                                        onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                                        sx={{ float: 'right' }}
                                        tabIndex={-1}
                                    >
                                        {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                )
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    this.handleRegister();
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={this.handleRegister}
                            sx={{ width: '200px', marginBottom: '20px' }}
                        >
                            Sign Up
                        </Button>
                        <Button variant="text" onClick={() => { window.location.href = "/login" }} size='small'>sign in</Button>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: '20px',

                        }}>
                            <Checkbox
                                checked={this.state.checked}
                                onClick={(event) => this.handleChange(event)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <label htmlFor="agreement">
                                Agree to the
                                <a href="/agreement" target="_blank" style={{ color: '#3874cb', textDecoration: 'none' }}> User Agreement </a>
                                and
                                <a href="/privacy" target="_blank" style={{ color: '#3874cb', textDecoration: 'none' }}> Privacy Policy </a>
                            </label>
                        </Box>
                    </Box>
                </Box>

                <Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={this.handleCloseSnackbar} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

            </Box>
        )
    }
}
