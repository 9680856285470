import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default class PDBViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
            type: null,
        };
    }


    async componentDidMount() {
        const task_id = window.location.search.substring(1);
        const message = new FormData();
        message.append('task_id', task_id)
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get_one_result`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                const url = URL.createObjectURL(new Blob([data.pdb], { type: 'text/plain' }));
                const type = data.is_cif ? "cif" : "pdb";
                console.log(url, type);
                this.setState({ url, type });
            } else {
                console.log(data.message);
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const gridStyle = {
            height: 'calc(100vh - 64px)',
            position: 'fixed',
            display: 'block',
            width: '100vw',
            bottom: 0,
            right: 0,
            transition: 'all 0.3s',
        };
        return (
            <div style={gridStyle}>
                {
                    this.state.url ?
                        <pdbe-molstar
                            custom-data-url={this.state.url}
                            custom-data-format={this.state.type}
                            bg-color-r="255"
                            bg-color-g="255"
                            bg-color-b="255"
                            sequence-panel
                            expanded="true"
                            alphafold-view="true"
                        >
                        </pdbe-molstar> :
                        <h1 style={{ position: 'absolute', bottom: '10px', left: '50px' }}>loading ...</h1>
                }
                <Button
                    variant="contained"
                    onClick={() => {
                        window.location.href = '/task';
                    }}
                    aria-label="返回到任务"
                    startIcon={<ArrowBackIcon />}  // 在这里添加图标
                    sx={{
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                        zIndex: 100,
                    }}
                >
                    Back to Task
                </Button>
            </div>
        )
    }
}
