import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
// import CardContainer from './CardContainer';
// import Bg from './img/bg.jpg';
import Card from './Card';
import Button from '@mui/material/Button';
import UploadPage from './UploadPage';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_list: [],
      messageOpen: false,
      messageType: 'success',
      countSuccess: 0,
      countFail: 0,
      choose: 'AFM',
      uploadComponent: null,
    }
  }

  setCountSuccess = (count) => {
    this.setState({ countSuccess: count });
  };

  setCountFail = (count) => {
    this.setState({ countFail: count });
  }

  setMessageType = (type) => {
    this.setState({ messageType: type });
  }

  setMessageOpen = (open) => {
    this.setState({ messageOpen: open });
  };

  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.update_list();
    }
  };

  handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setMessageOpen(false);
  };

  update_list = () => {
    const formData = new FormData();
    fetch(`${process.env.REACT_APP_API_URL}/api/get_task_names`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    }).then(response => {
      if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
      .then(data => {
        this.setState({ task_list: data.task_names });
      })
      .catch(error => {
        // 在这里处理错误
        console.error('There was a problem with your fetch operation:', error);
      });
  }

  handleTaskCreate = () => {
    if (!localStorage.getItem('token')) {
      window.location.href = '/login';
      return;
    }

    this.setState({ uploadComponent: true });
  }

  handleTaskCreateClose = () => {
    this.setState({ uploadComponent: false });
  }

  render() {
    return (
      <Box sx={{
        minHeight: 'calc(100vh - 64px)',
        position: 'relative',
        display: 'block',
        width: '100vw',
        transition: 'all 0.3s',
        overflowy: 'auto',
        // background: `url(${Bg}) repeat center center fixed`,
        backgroundColor: '#f3f5f7',
        backgroundSize: 'cover',
        zIndex: '-2',
      }}>
        {!this.state.uploadComponent &&
          <Box>
            <Box sx={{
              fontWeight: 'bold',
              padding: '10vh 0 10vh 0',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              alignContent: 'center',
            }}
              component={'div'}
            >
              <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ color: '#919191', fontSize: '27.3px', fontWeight: '500' }}>
                  ShanghaiTech University
                </Box>
                <Box component={'div'} sx={{ color: '#000000', fontSize: '46.82px', fontWeight: '800', margin: '10px 0' }}>
                  AI Protein Prediction Platform
                </Box>
                <Box component={'div'} sx={{ color: '#464DD1', fontSize: '28px', fontWeight: '500' }}>
                  Begin with choosing a model
                </Box>
              </Box>
              <Box
                component={'div'}
                sx={{
                  color: '#464DD1',
                  fontSize: '23.63px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}
                onMouseEnter={(e) => { e.target.style.textDecoration = 'underline' }}
                onMouseLeave={(e) => { e.target.style.textDecoration = 'none' }}
                onClick={() => { window.location.href = '/docs'; }}>
                Learn more {`>>`}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                maxWidth: '1600px',
              }}
                component="div"
              >
                <Box
                  onClick={() => { this.setState({ choose: 'AFM' }) }}
                >
                  <Card
                    type='AFM'
                    choose={this.state.choose}
                  />
                </Box>

                <Box
                  onClick={() => { this.setState({ choose: 'ESM' }) }}
                >
                  <Card
                    type='ESM'
                    choose={this.state.choose}
                  />
                </Box>
                <Box
                  onClick={() => { this.setState({ choose: 'LigandMPNN' }) }}
                >
                  <Card
                    type='LigandMPNN'
                    choose={this.state.choose}
                  />
                </Box>

              </Box>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  margin: '20px 0',
                  color: '#517DFF',
                }}
                onClick={this.handleTaskCreate}
              >
                Create New Project
              </Button>
            </Box>
          </Box>
        }
        {this.state.uploadComponent &&
          <UploadPage
            task_list={this.state.task_list}
            updateTaskList={this.update_list}
            cardType={this.state.choose}
            close={this.handleTaskCreateClose}
            setCountSuccess={this.setCountSuccess}
            setCountFail={this.setCountFail}
            setMessageType={this.setMessageType}
            setMessageOpen={this.setMessageOpen}
          />
        }
        <Snackbar
          open={this.state.messageOpen}
          autoHideDuration={6000}
          onClose={this.handleMessageClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleMessageClose}
            severity={this.state.messageType}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {
              this.state.countSuccess === 0 && this.state.countFail === 0 ? 'No task created!' :
                this.state.countSuccess === 0 ? 'All tasks failed to create!' :
                  this.state.countFail === 0 ? 'All tasks created successfully!' :
                    this.state.countSuccess + ' Task created successfully! ' + this.state.countFail + ' Task failed to create!'
            }
          </Alert>
        </Snackbar>
      </Box>
    )
  }
}
