import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ErrorIcon from '@mui/icons-material/Error';
// import WarningIcon from '@mui/icons-material/Warning';
import NextStepIcon from './scaleProtein.svg';
import ArticleIcon from '@mui/icons-material/Article';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate"
                    sx={{
                        height: '10px', backgroundColor: '#F5F5F5', borderRadius: '5px',
                        '& .MuiLinearProgress-bar': {
                            // backgroundColor: props.value === 100 ? '#3FC375' : props.value === 0 ? '#FFA800' : '#FFA800',
                            backgroundColor: '#0779FF',
                            borderRadius: '5px',
                        }
                    }}
                    {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};




function Dot({ color }) {
    return (
        <Box
            sx={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: color,
                display: 'inline-block',
            }}
        />
    )
}


function Row({
    task_name,
    row,
    setLoading,
    setProgress,
    get_tasks,
    setReportData,
    setReportOpen,
    setLigandNextOpen,
    setLiFileName,
    setLiFileId
}) {
    const [open, setOpen] = React.useState(false);
    const [num_task, setNumTask] = React.useState(1);
    const [done_task, setDoneTask] = React.useState(0);
    const [failed, setFailed] = React.useState(0);
    const [pending, setPending] = React.useState(0);
    const [running, setRunning] = React.useState(0);
    const [length, setLength] = React.useState(0);

    function viewPDB(pdbID) {
        // console.log('/viewer?' + pdbID);
        window.location.href = '/viewer?' + pdbID;
    }

    React.useEffect(() => {
        let num = 0;
        let done = 0;
        let fail = 0;
        let running = 0;
        let pending = 0;
        let _length = 0;
        for (let key in row["subtasks"]) {
            num += 1;
            if (row["subtasks"][key]["status"] === 0) {
                pending += 1;
            } else if (row["subtasks"][key]["status"] === 1) {
                running += 1;
            } else if (row["subtasks"][key]["status"] === 2) {
                done += 1;
            } else if (row["subtasks"][key]["status"] === 3) {
                fail += 1;
            }
            _length += row["subtasks"][key]['sequence_length']
        }
        setNumTask(num);
        setDoneTask(done);
        setFailed(fail);
        setPending(pending);
        setRunning(running);
        setLength(_length);
    }, [row]);

    async function downSubTask(task_id, file_name) {
        const message = new FormData();
        message.append('task_id', task_id);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/download_subtask`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            if (response.status === 200) {
                const reader = response.body.getReader();
                const contentLength = +response.headers.get('Content-Length');
                let receivedLength = 0; // 接收到的字节数
                let chunks = []; // 用来存储数据块的数组

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength * 100);
                }

                setLoading(false);
                setProgress(0);
                const blob = new Blob(chunks);
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${file_name}.zip`;
                link.click();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log('Download failed:', response.statusText);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteSubTask(task_id, file_name) {
        console.log('delete subtask', task_id);
        const message = new FormData();
        message.append('task_id', task_id);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete_task`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            const data = await response.json();
            if (response.status === 200) {
                get_tasks();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function deleteTask(row) {
        for (let key in row["subtasks"]) {
            deleteSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])
        }
    }

    async function downTask(task_name) {
        const message = new FormData();
        message.append('task_name', task_name);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/download_task`, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            if (response.status === 200) {
                const reader = response.body.getReader();
                const contentLength = +response.headers.get('Content-Length');
                let receivedLength = 0; // 接收到的字节数
                let chunks = []; // 用来存储数据块的数组

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    setProgress(receivedLength / contentLength * 100);
                }

                setLoading(false);
                setProgress(0);
                const blob = new Blob(chunks);
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${task_name}.zip`;
                link.click();
            } else if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else {
                console.log('Download failed:', response.statusText);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    async function reportSubTask(task_id, file_name, time, row) {
        let cacheTaskReport = localStorage.getItem('cacheTaskReport');
        cacheTaskReport = cacheTaskReport ? JSON.parse(cacheTaskReport) : {};
        // console.log(row)
        if (!(task_id in cacheTaskReport)) {
            const message = new FormData();
            message.append('task_id', task_id);
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get_report_plots`, {
                    method: 'POST',
                    body: message,
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                    }
                });
                if (response.status === 200) {
                    const data = await response.json();
                    setReportData({
                        'task_id': task_id,
                        'filename': file_name,
                        'time': time,
                        'row': row,
                        'data': data
                    });
                    // console.log(data)
                    setReportOpen(true);
                    setLoading(false);
                    cacheTaskReport[task_id] = {
                        'task_id': task_id,
                        'filename': file_name,
                        'time': time,
                        'row': row,
                        'data': data
                    };
                    localStorage.setItem('cacheTaskReport', JSON.stringify(cacheTaskReport));
                } else if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else {
                    console.log('Download failed:', response.statusText);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        } else {
            let data = cacheTaskReport[task_id];
            setReportData({
                'task_id': data['task_id'],
                'filename': data['filename'],
                'time': data['time'],
                'row': row,
                'data': data['data']
            });
            setReportOpen(true);
        }
    }

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired,
    };

    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)} id={task_name} sx={{
                // borderTop: '20px solid #E5EEF8',
                backgroundColor: open ? '#FAFAFA' : '#ffffff',
                position: 'relative'
            }}>
                {/* <TableCell sx={{ width: '8%' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell> */}
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'left', color: '#000', width: '300px', paddingLeft: '40px' }} component="th" scope="row">
                    {
                        task_name.length > 20 ?
                            <LightTooltip title={task_name}>
                                <Box>{task_name.substring(0, 18)}...</Box>
                            </LightTooltip>
                            :
                            <Box>{task_name}</Box>
                    }
                </TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#000', width: '150px' }}>{row["type"]}</TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#000', width: '100px' }}>{length}</TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#000', width: '250px' }}>{moment.unix(row.start_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#000', width: '100px' }}>
                    {/* {
                        done_task === num_task ? <CheckCircleIcon sx={{ color: '#3FC375' }} /> :
                            failed === num_task ? <ErrorIcon sx={{ color: 'red' }} /> :
                                done_task + failed === num_task ? <WarningIcon sx={{ color: '#FFA800' }} /> :
                                    <CircularProgressWithLabel value={(done_task + failed) / num_task * 100} />
                    } */}
                    <Dot
                        color={done_task === num_task ? '#3FC375'   // green
                            : running > 0 ? '#FFA800'               // yellow
                                : pending > 0 ? '#989898'               // grey
                                    : failed > 0 ? '#C92B26'
                                        : '#989898'
                        }
                    />
                    {/* <Dot color={done_task === num_task ? '#3FC375' : failed === num_task ? '#C92B26' : done_task + failed === num_task ? '#C92B26' : done_task === 0 & failed === 0 ? '#989898' : '#FFA800'} /> */}
                </TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#000', width: '250px' }}>
                    {/* {
                        done_task === num_task ? <CheckCircleIcon sx={{ color: '#3FC375' }} /> :
                            failed === num_task ? <ErrorIcon sx={{ color: 'red' }} /> :
                                done_task + failed === num_task ? <WarningIcon sx={{ color: '#FFA800' }} /> :
                                    <CircularProgressWithLabel value={(done_task + failed) / num_task * 100} />
                    } */}

                    <LinearProgressWithLabel value={(done_task + failed) / num_task * 100} />
                </TableCell>
                <TableCell sx={{ fontSize: '18px', fontWeight: '450', textAlign: 'center', color: '#517DFF', width: '300px' }}>
                    <LightTooltip title={'Download task'}>
                        <span>
                            <IconButton
                                aria-label="download"
                                sx={{ color: '#000' }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    downTask(task_name);
                                }}
                                disabled={(done_task > 0 && done_task + failed === num_task) ? false : true}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                    <LightTooltip title={'Delete task'}>
                        <span>
                            <IconButton
                                aria-label="delete"
                                sx={{ color: '#000' }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    deleteTask(row);
                                }}
                                disabled={(done_task + failed === num_task) ? false : true}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </LightTooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{
                    padding: 0,
                    backgroundColor: '#fff',
                    width: '100%',
                }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            {/* <Typography variant="h6" gutterBottom component="div"
                                sx={{
                                    padding: '20px',
                                    margin: '0px 0 20px 0',
                                    background: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box component={'div'} sx={{ display: 'inline-block', width: '100px' }}>Options: </Box>
                                <Box component={'div'} sx={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                                    {Object.keys(row["options"]).map((key) => (
                                        <div key={key} style={{
                                            border: '2px solid #f5f5f5',
                                            backgroundColor: '#fff',
                                            color: '#000',
                                            marginLeft: '10px',
                                            padding: '2px 10px',
                                            borderRadius: '20px',
                                            display: 'inline-block',
                                            marginTop: '10px'
                                        }}>
                                            {key}: {row["options"][key]}
                                        </div>
                                    ))}
                                </Box>

                            </Typography> */}
                            <Table>
                                <TableBody>
                                    {Object.keys(row["subtasks"]).map((key) => (
                                        <TableRow
                                            key={row["subtasks"][key]["subtask_id"]}
                                            id={row["subtasks"][key]["subtask_id"]}
                                            hover={row["subtasks"][key]["status"] === 1 ? true : false}
                                            sx={{
                                                '& td, & th': { border: 0 },
                                                width: '100%',
                                                position: 'relative',
                                            }}
                                        >
                                            {/* <TableCell sx={{ width: '8%', fontSize: '17px' }}></TableCell> */}
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'left', color: '#000', width: '300px', paddingLeft: '40px' }}>
                                                {
                                                    row["subtasks"][key]["file_name"].length > 18 ?
                                                        <LightTooltip title={row["subtasks"][key]["file_name"]}>
                                                            <div>{row["subtasks"][key]["file_name"].substring(0, 18)}...</div>
                                                        </LightTooltip>
                                                        :
                                                        row["subtasks"][key]["file_name"]
                                                }
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '150px' }}>
                                                {/* {row["type"]} */}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '100px' }}>{row["subtasks"][key]["sequence_length"]}</TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '250px' }}>{moment.unix(row["subtasks"][key]["create_time"]).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '100px' }}>
                                                {/* {row["subtasks"][key]["status"] === 0 ?
                                                    <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#F9EEE2', color: '#FFA800' }}>Pending</span> :
                                                    row["subtasks"][key]["status"] === 1 ?
                                                        <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#F6D9F4', color: '#F065E1' }}>Running</span> :
                                                        row["subtasks"][key]["status"] === 2 ?
                                                            <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#D4DDFC', color: '#5F86FF' }}>Succeed</span> :
                                                            row["subtasks"][key]["status"] === 3 ?
                                                                <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: 'red', color: '#faf4f4' }}>Failed</span> :
                                                                row["subtasks"][key]["status"]
                                                } */}
                                                <Dot
                                                    color={row["subtasks"][key]["status"] === 2 ? '#3FC375'
                                                        : row["subtasks"][key]["status"] === 3 ? '#C92B26'
                                                            : row["subtasks"][key]["status"] === 0 ? '#989898'
                                                                : '#FFA800'
                                                    }
                                                />
                                                {/* <Dot color={row["subtasks"][key]["status"] === 2 ? '#3FC375' : row["subtasks"][key]["status"] === 3 ? '#C92B26' : row["subtasks"][key]["status"] === 0 ? '#989898' : '#FFA800'} /> */}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '250px' }}>
                                                {/* {row["subtasks"][key]["status"] === 0 ?
                                                    <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#F9EEE2', color: '#FFA800' }}>Pending</span> :
                                                    row["subtasks"][key]["status"] === 1 ?
                                                        <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#F6D9F4', color: '#F065E1' }}>Running</span> :
                                                        row["subtasks"][key]["status"] === 2 ?
                                                            <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: '#D4DDFC', color: '#5F86FF' }}>Succeed</span> :
                                                            row["subtasks"][key]["status"] === 3 ?
                                                                <span style={{ padding: '4px 10px', borderRadius: '10px', backgroundColor: 'red', color: '#faf4f4' }}>Failed</span> :
                                                                row["subtasks"][key]["status"]
                                                } */}
                                                {/* <LinearProgressWithLabel value={row["subtasks"][key]["status"] === 2 ? 100 : row["subtasks"][key]["status"] === 3 ? 0 : row["subtasks"][key]["status"] === 1 ? 50 : 0} /> */}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'center', color: '#000', width: '300px' }}>
                                                <LightTooltip title={'Download subtask'}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="download"
                                                            sx={{ color: 'black' }}
                                                            onClick={() => downSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])}
                                                            disabled={row["subtasks"][key]["status"] === 2 ? false : true}
                                                        >
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    </span>
                                                </LightTooltip>
                                                {
                                                    row["type"] === 'ligandmpnn' ?
                                                        <></>
                                                        :
                                                        <LightTooltip title={'View subtask report'}>
                                                            <span>
                                                                <IconButton
                                                                    aria-label="report"
                                                                    sx={{ color: 'black' }}
                                                                    onClick={() => reportSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"], moment.unix(row["subtasks"][key]["create_time"]).format('YYYY-MM-DD HH:mm:ss'), row)}
                                                                    disabled={row["subtasks"][key]["status"] === 2 ? false : true}
                                                                >
                                                                    <ArticleIcon />
                                                                </IconButton>
                                                            </span>
                                                        </LightTooltip>
                                                }
                                                <LightTooltip title={row['type'] === 'ligandmpnn' ? 'Predict protein' : 'View predict result'}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="view"
                                                            sx={{ color: 'black' }}
                                                            onClick={() => {
                                                                if (row["type"] === 'ligandmpnn') {
                                                                    setLiFileName(row["subtasks"][key]["file_name"])
                                                                    setLiFileId(row["subtasks"][key]["subtask_id"])
                                                                    setLigandNextOpen(true)
                                                                } else {
                                                                    viewPDB(row["subtasks"][key]["subtask_id"])
                                                                }
                                                            }}
                                                            disabled={row["subtasks"][key]["status"] === 2 ? false : true}
                                                        >
                                                            {
                                                                row["type"] === 'ligandmpnn' ?
                                                                    <img src={NextStepIcon} alt="next-step" style={{ width: '20px', height: '20px' }} />
                                                                    :
                                                                    <VisibilityIcon />
                                                            }
                                                        </IconButton>
                                                    </span>
                                                </LightTooltip>
                                                <LightTooltip title={'Delete subtask'}>
                                                    <span>
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{ color: 'black' }}
                                                            onClick={() => deleteSubTask(row["subtasks"][key]["subtask_id"], row["subtasks"][key]["file_name"])}
                                                            disabled={
                                                                (
                                                                    row["subtasks"][key]["status"] === 2 ||
                                                                    row["subtasks"][key]["status"] === 3 ||
                                                                    row["subtasks"][key]["status"] === 0
                                                                )
                                                                    ? false : true
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </LightTooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

export default Row;