import React, { Component } from 'react';
// import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';

export default class Feedback extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     feedback: '',
  //     contact: '',
  //     snackbarOpen: false,
  //     snackbarMessage: '',
  //     snackbarSeverity: 'success',
  //   };
  // }

  // componentDidMount() {
  //   if (!localStorage.getItem('username')) {
  //     window.location.href = '/login';
  //   }
  // }

  // handleChange = (event) => {
  //   this.setState({ feedback: event.target.value });
  // }

  // handleChangeContact = (event) => {
  //   this.setState({ contact: event.target.value });
  // }

  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   const message = new FormData();
  //   message.append('feedback', this.state.feedback);
  //   message.append('contact', this.state.contact);
  //   fetch(`${process.env.REACT_APP_API_URL}/api/feedback`, {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': localStorage.getItem('token'),
  //     },
  //     body: message,
  //   })
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Network response was not ok.');
  //     })
  //     .then(data => {
  //       this.setState({
  //         snackbarOpen: true,
  //         snackbarMessage: 'Feedback submitted successfully!',
  //         snackbarSeverity: 'success',
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({
  //         snackbarOpen: true,
  //         snackbarMessage: 'Error submitting feedback.',
  //         snackbarSeverity: 'error',
  //       });
  //       console.error('There was a problem with the fetch operation:', error);
  //     });

  //   this.setState({ feedback: '' }); // 清空表单
  // }

  // handleCloseSnackbar = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   this.setState({ snackbarOpen: false });
  // };

  render() {
    return (
      // eslint-disable-next-line 
      <iframe src="https://forms.office.com/r/1wTS6Hn78u?embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: 'none', maxWidth:'100%', maxHeight:'100vh', width: '100vw', height: 'calc(100vh - 64px)'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
      // <Box sx={{
      //   height: 'calc(100vh - 64px)',
      //   position: 'relative',
      //   width: '100vw',
      //   transition: 'all 0.3s',
      //   display: 'flex',
      //   justifyContent: 'center',
      //   alignItems: 'center',
      //   flexDirection: 'column',
      // }}
      //   component="div">
      //   <Box sx={{
      //     display: 'flex',
      //     justifyContent: 'center',
      //     alignItems: 'center',
      //     flexDirection: 'column',
      //     // border: '1px solid black',
      //     borderRadius: '10px',
      //     backgroundColor: '#fff',
      //     padding: '60px 100px 80px 100px',
      //   }}
      //     component="div"
      //   >
      //     <Container maxWidth="sm">
      //       <Typography variant="h4" component="h1" gutterBottom>
      //         Feedback
      //       </Typography>
      //       <Typography gutterBottom>
      //         We are more than happy to hear your feedback! Please leave your feedback below. It can be anything related to our AI Protein Platform, from a bug report to a feature request. We will try our best to improve our platform based on your feedback. 中/英文皆可。
      //       </Typography>
      //       <form onSubmit={this.handleSubmit}>
      //         <Box mb={2}>
      //           <TextField
      //             label="Your Feedback"
      //             multiline
      //             rows={10}
      //             fullWidth
      //             variant="outlined"
      //             value={this.state.feedback}
      //             onChange={this.handleChange}
      //             required
      //           />
      //         </Box>
      //         <Box mb={2}>
      //           <TextField
      //             label="Contact Information (Optional)"
      //             multiline
      //             rows={1}
      //             fullWidth
      //             variant="outlined"
      //             value={this.state.contact}
      //             onChange={this.handleChangeContact}
      //           />
      //         </Box>
      //         <Button type="submit" variant="contained" color="primary">
      //           Submit
      //         </Button>
      //       </form>
      //       <Snackbar
      //         open={this.state.snackbarOpen}
      //         autoHideDuration={6000}
      //         onClose={this.handleCloseSnackbar}
      //         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      //       >
      //         <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
      //           {this.state.snackbarMessage}
      //         </Alert>
      //       </Snackbar>
      //     </Container>
      //   </Box>
      // </Box>
    );
  }
}
