import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/Send';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailError: '',
            codeError: '',
            codeTime: 0,
            passwordError: '',
            passwordAgainError: '',
            passwordVisible: false,
            errorMessage: '',
            snackbarOpen: false,
            alertSeverity: 'error',

        };
    }

    componentDidMount() {
        // 组件挂载后设置定时器
        this.timer = setInterval(() => {
            if (this.state.codeTime > 0) {
                this.setState({ codeTime: this.state.codeTime - 1 });
            }
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        // 检查codeTime的变化，如果不再需要定时器，可以在这里清除
        if (this.state.codeTime === 0 && prevState.codeTime > 0) {
            clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        // 组件卸载前清除定时器
        clearInterval(this.timer);
    }

    handleSendCode = async () => {
        this.setState({ codeTime: 60 });
        const email = document.getElementById('email-reset').value;
        if (email.length === 0) {
            this.throwError("Please fill in email");
        }
        const formData = new FormData();
        formData.append('email', email);
        fetch(`${process.env.REACT_APP_API_URL}/api/resend_code`, {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                this.setState({ codeTime: 0 });
                throw new Error('Network response was not ok');
            })
            .then(data => {
                this.throwError("Code sent successfully! Please check your Email", true);
            })
            .catch(error => {
                this.throwError("Invalid email");
                this.setState({ codeTime: 0 });
            });
    };

    handleReset = async () => {
        this.throwError("Reseting...", true);
        const email = document.getElementById('email-reset').value;
        const code = document.getElementById('code-reset').value;
        const password = document.getElementById('password-reset').value;
        const passwordAgain = document.getElementById('password-reset-again').value;

        if (email.length === 0 || code.length === 0 || password.length === 0 || passwordAgain.length === 0) {
            this.throwError("Please fill in all fields");
            return;
        } else if (password !== passwordAgain) {
            this.throwError("Passwords do not match");
            return;
        } else if (password.length < 6 || password.length > 16) {
            this.throwError("Password must be at least 6 characters and at most 16 characters");
            return;
        }

        const formData = new FormData();
        formData.append('email', email);
        formData.append('code', code);
        formData.append('new_password', password);


        fetch(`${process.env.REACT_APP_API_URL}/api/resetpassword`, {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok');
            })
            .then(data => {
                this.throwError("Password reset successfully! Redirects to the login page...", true);
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            })
            .catch(error => {
                this.throwError("Invalid email or code");
            });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpen: false });
    };

    throwError = (message, green = false) => {
        if (green) {
            this.setState({ alertSeverity: 'success' });
        } else {
            this.setState({ alertSeverity: 'error' });
        }
        this.setState({
            errorMessage: message,
            snackbarOpen: true,
        });
        setTimeout(() => {
            this.setState({ snackbarOpen: false });
        }, 3000);
        return;
    }

    render() {
        return (
            <Box sx={{
                height: 'calc(100vh - 64px)',
                position: 'relative',
                width: '100vw',
                transition: 'all 0.3s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
                component="div">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    border: '1px solid black',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    padding: '60px 100px 80px 100px'
                }}
                    component="div"
                >
                    <h1>Reset Password</h1>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                        <TextField
                            required
                            error={this.state.emailError ? true : false}
                            id="email-reset"
                            label="Email"
                            variant="outlined"
                            helperText={this.state.emailError ? this.state.emailError : 'Please enter your email address'}
                            onFocus={() => this.setState({ emailError: '' })}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            inputProps={{
                                maxLength: 30
                            }}
                        />
                        <TextField
                            required
                            error={this.state.codeError ? true : false}
                            id="code-reset"
                            label="Code"
                            variant="outlined"
                            helperText={this.state.codeError ? this.state.codeError : 'Please enter your validation code'}
                            onFocus={() => this.setState({ codeError: '' })}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            InputProps={{
                                maxLength: 10,
                                endAdornment: (
                                    <Button
                                        onClick={this.handleSendCode}
                                        size='small'
                                        tabIndex={-1}
                                        variant='outlined'
                                        disabled={this.state.codeTime > 0}
                                        color='primary'
                                    >
                                        {this.state.codeTime > 0 ? this.state.codeTime : <SendIcon />}
                                    </Button>
                                )
                            }}
                        />
                        <TextField
                            required
                            error={this.state.passwordError ? true : false}
                            id="password-reset"
                            label="Password"
                            variant="outlined"
                            helperText={this.state.passwordError ? this.state.passwordError : 'Please enter your new password'}
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            InputProps={{
                                maxLength: 16,
                                type: this.state.passwordVisible ? 'text' : 'password',
                                endAdornment: (
                                    <IconButton
                                        onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                                        sx={{ float: 'right' }}
                                        tabIndex={-1}
                                    >
                                        {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                ),
                                onBlur: (event) => {
                                    const password = event.target.value;
                                    if (password.length < 6 || password.length > 16) {
                                        this.setState({ passwordError: 'Password must be between 6 and 16 characters' });
                                    } else {
                                        this.setState({ passwordError: '' });
                                    }
                                }
                            }}
                        />
                        <TextField
                            required
                            error={this.state.passwordAgainError ? true : false}
                            id="password-reset-again"
                            label="Password Again"
                            variant="outlined"
                            helperText="Please enter your new password again"
                            sx={{
                                marginBottom: '20px',
                                width: '250px'
                            }}
                            InputProps={{
                                maxLength: 16,
                                type: this.state.passwordVisible ? 'text' : 'password',
                                endAdornment: (
                                    <IconButton
                                        onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                                        sx={{ float: 'right' }}
                                        tabIndex={-1}
                                    >
                                        {this.state.passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                ),
                                onBlur: (event) => {
                                    const password = event.target.value;
                                    if (password !== document.getElementById('password-reset').value) {
                                        this.setState({ passwordAgainError: 'Passwords do not match' });
                                    } else {
                                        this.setState({ passwordAgainError: '' });
                                    }
                                }
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    this.handleReset();
                                }
                            }}
                        />
                        <Button variant="contained" onClick={this.handleReset} sx={{ width: '200px', marginBottom: '20px' }}>Reset Password</Button>
                        <Button variant="text" onClick={() => { window.location.href = "/login"; }} size='small'>sigin in</Button>
                    </Box>
                </Box>

                <Snackbar open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={this.handleCloseSnackbar} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

            </Box>
        )
    }
}
