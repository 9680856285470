import Box from '@mui/material/Box';

export default function Agreement() {
    return (
        <Box sx={{ backgroundColor: '#fff', width: '100%', padding: '20px' }}>

            <div className="font-title">
                寰渺AI蛋白质平台用户服务协议
            </div>

            <div className="font-label">
                本协议首次发布于：2024年4月12日
                <br />
                更新于：2024年4月12日
            </div>

            <div className="font-body">
                欢迎您与寰渺（详见定义条款）签署本《用户服务协议》并使用寰渺AI蛋白质平台（下称“本平台”）及相关服务！
            </div>
            <div className="font-body">
                本平台由寰渺科技（上海）有限公司（下称“寰渺”或“本公司”）负责运营。本《寰渺AI蛋白质平台用户服务协议》（下称“本协议”）、《隐私政策》及其相关修订版本的双方为寰渺与注册用户（下称“用户”或“您”），本协议具有合同效力，适用于您在本平台的全部活动。
            </div>

            <div className="font-label font-f">
                【特别提示】
            </div>
            <div className="font-body">
                一、请您在开始使用本平台前务必审慎阅读本协议，充分理解各条款内容，特别是免除或者限制责任的条款（该等条款通常含有“不负任何责任”、“无义务”等词汇）、其他限制用户权利的条款（该等条款通常含有“不得”等词汇）、法律适用和争议解决条款。该等条款将以粗体标识，您应重点阅读。
            </div>
            <div className="font-body">
                二、如您不同意本协议的条款，请立即停止注册并退出平台账号登录，同时停止使用平台相关服务。您一旦点击“接受”、“同意”或其他类似含义的按键，或者成功注册账号、开始使用及/或继续使用本平台或者相关服务，即表明您已阅读并充分理解本协议之内容，并且已同意遵守本协议。
            </div>
            <div className="font-body">
                三、寰渺有权根据法律法规变化、保护消费者权益需要等合理合法情况，不定时地修订本协议，如本协议的任何变更以本平台最新公布的内容为准。经修订的新协议公布七日后即生效，对新协议生效之后成功注册的用户发生法律效力。对于新协议生效之前注册的用户，若用户在新协议生效后继续使用本平台提供的各项服务，则表明用户已充分阅读并同意遵守新协议。若您拒绝接受新协议，您必须放弃使用本平台及相关服务（包括但不限于使用账号和密码登录本平台、访问或使用服务等）。
            </div>
            <div className="font-body">
                四、您需特别注意，由于本平台创作服务生成的所有内容都是由人工智能模型生成，虽已经过不断的自动及人工敏感数据过滤，但仍不排除其中部分信息具有瑕疵、不合理或引人不适的情形，您对使用本平台服务所生成的所有内容负有审慎审核的义务，如生成内容涉嫌违法违规、敏感有害、违反公序良俗等情况，您不得通过截图、下载、拍照、录像、屏幕捕捉等方式留存相关图片，并不得对此进行发送、传播或商业化使用。否则，因您使用生成内容进行任何违法或不当的活动等被追责所产生的所有责任，由您自行承担。
            </div>
            <div className="font-body">
                五、如您对本协议内容有任何疑问、投诉、意见或建议，您可按照本协议载明的联系方式与寰渺联系。
            </div>

            <div className="font-headline">
                1 定义
            </div>

            <div className="font-body">
                1.1 本协议：指《用户服务协议》、《隐私政策》及其相关修订版本。上述内容一经正式发布，即构成本协议不可分割的组成部分。
            </div>
            <div className="font-body">
                1.2 寰渺：又称“本公司”，指寰渺科技（上海）有限公司。
            </div>
            <div className="font-body">
                1.3 用户：又称“您”，指通过寰渺提供的合法途径使用本平台的自然人。
            </div>
            <div className="font-body">
                1.4 账号：指用户为使用本平台，依照本协议第3条及相关法规政策规定成功注册的用户账号。
            </div>
            <div className="font-body">
                1.5 本平台：指寰渺AI蛋白质平台。
            </div>
            <div className="font-body">
                1. 网站：指platform.cellverse.tech。
            </div>
            <div className="font-body">
                1.7 服务：指寰渺向用户提供的与本平台相关的各项服务。
            </div>
            <div className="font-body">
                1.8 材料：指由寰渺为维护或升级本平台而专门生产、收集、汇编和维护的任何数据、信息、技术等。
            </div>
            <div className="font-headline">
                2 服务内容、形式和费用
            </div>
            <div className="font-body">
                2.1 服务内容：本平台系寰渺科技（上海）有限公司为用户提供蛋白质相关的结构预测、蛋白质序列优化等蛋白质相关模型的在线计算，平台可提供服务内容有：蛋白质结构预测、蛋白质序列优化、计算结果预览、分析报告自动生成等服务，后续提供的服务内容将随着本平台不断更新迭代而逐渐增多，欢迎您登录本平台浏览和使用。
            </div>
            <div className="font-body">
                2.2 服务形式：您需要访问网站platform.cellverse.tech使用本平台及相关服务。寰渺在此授予您一项个人的、不可转让及非排他性的许可，您仅可为访问或使用本服务的目的而使用本平台，而不得用于其他任何商业目的。
            </div>
            <div className="font-body">
                2.3 费用：
            </div>
            <div className="font-body">
                2.3.1 本平台的服务以免费试用的方式提供。
            </div>
            <div className="font-body">
                2.3.2 在内测阶段，您将通过（包括但不限于）通过网站、邮箱联系我们的方式提升使用次数等增值服务，具体收费标准以本平台后续提供和相关页面详情内容为准。
            </div>
            <div className="font-body">
                2.3.3 本平台可能根据实际需要对增值服务的规则、方式、收费进行修改和变更。前述修改、变更或开始收费前，本平台将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
            </div>
            <div className="font-headline">
                3 账号
            </div>
            <div className="font-body">
                3.1 注册账号：您需要根据页面指示填写您的手机号码获取验证码登录，您应当同意本协议和《隐私政策》，并依法如实填写实名认证信息。寰渺有权对您提交的实名认证信息进行审核。您的首次成功登录即视为完成账号的注册。您注册成功后，本平台将给予您一个供您使用本平台的账号，该账号在本平台的所有行为均视为您本人行为，您应当对该等行为负法律责任。
            </div>
            <div className="font-body">
                3.2 您理解并承诺，您所设置的账号不得违反国家法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。
            </div>
            <div className="font-body">
                3.3 注册成功后，您可以完善头像、昵称等资料，但是否完善该等资料均不会影响您继续持有本账号。但是，该等资料中不得出现任何违法和不良信息。
            </div>
            <div className="font-body">
                3.4 您有权设置账号的名称、登录密码等，该用户账号和密码由您自行妥善保管。寰渺在任何情况下均不会主动要求您提供您的登录密码。因此，若账号因您主动泄露登录密码或因您遭受他人攻击、诈骗等行为导致的损失及后果，寰渺对此并不承担任何责任，您应通过司法、行政等救济途径向侵权行为人索赔或追偿。
            </div>
            <div className="font-body">
                3.5 如有合法证据证明或寰渺合理判断您存在违反上述条款的情形，寰渺可采取冻结、注销账号、停止提供本平台服务等措施而无需向您事先通知，并根据本协议第8条向您追究违约责任。如给寰渺造成损失的，您还应承担一切法律责任并赔偿全部损失。
            </div>
            <div className="font-headline">
                4 用户行为规范
            </div>
            <div className="font-body">
                4.1 用户资格：
            </div>
            <div className="font-body">
                4.1.1 在您开始使用本平台及相关服务前，请确认您已具备中华人民共和国大陆地区法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力而使用本平台，则您及您的监护人应依照法律规定承担因此而导致的一切后果。您知悉，无民事行为能力人、限制民事行为能力人违反本协议或违法使用本平台的，其与寰渺签署的本协议自始无效。
            </div>
            <div className="font-body">
                4.1.2 未成年人使用本平台：
            </div>
            <div className="font-body">
                4.1.2.1 若您是未满18周岁的未成年人，您应在您的监护人监护、指导下并获得监护人同意的情况下，认真阅读并同意本协议后，方可使用本平台及相关服务。
            </div>
            <div className="font-body">
                4.1.2.2 寰渺十分重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应在取得监护人的同意以及在监护人指导下正确使用本平台及相关服务。
            </div>
            <div className="font-body">
                4.1.2.3 未成年人用户及其监护人理解并确认：如您违反法律法规或本协议，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。
            </div>
            <div className="font-body">
                4.1.2.4 未成年人使用本平台及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯，遵守《全国青少年网络文明公约》。
            </div>
            <div className="font-body">
                4.1.2.5 监护人特别提示：如您的被监护人使用本平台及相关服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册本平台账号，本平台将有权认为其已取得您的同意。您的被监护人在使用本平台及相关服务时可能使用本协议第2.3条所涉及的付费等功能。您作为监护人，请保管好您的支付设备、支付账号及支付密码等，以避免被监护人在未取得您同意的情况下通过您的本平台账号使用付费等功能。
            </div>
            <div className="font-body">
                4.1.2.6 为更好地保护未成年人隐私权益，寰渺特此提示您：慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在本平台及相关服务展示未成年人的肖像、声音等信息，且允许本平台依据本协议使用、处理该等与未成年人相关的内容。
            </div>
            <div className="font-body">
                4.2 寰渺将只允许用户通过寰渺指定的且合法的方式使用本平台服务和材料。在使用本平台的过程中，用户在任何情况下均不得从事下列任何行为：
            </div>
            <div className="font-body">
                4.2.1 未经寰渺事先书面同意，转让、租赁、出售、出口、进口、分销或留置获准访问本平台和材料的权限给任何第三方；
            </div>
            <div className="font-body">
                4.2.1.1 使用未经寰渺授权或许可的任何插件、外挂、系统或第三方工具对本平台的正常运行进行干扰、破坏、修改或施加其他影响；
            </div>
            <div className="font-body">
                4.2.1.2 利用本平台发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱、诽谤、色情、暴力、引起他人不适及任何违反国家法律法规政策的内容，或者设置含有上述内容的网名、角色名，或者发布、传送、传播违法广告信息、营销信息及垃圾信息等利用本平台侵害他人知识产权、肖像权、隐私权、名誉权、个人信息等合法权利或权益；
            </div>
            <div className="font-body">
                4.2.2 利用本平台进行任何危害计算机系统和网络安全的行为，包括但不限于：
            </div>
            <div className="font-body">
                4.2.2.1 非法侵入计算机信息系统或网络、干扰其功能正常使用、窃取网络数据等危害网络安全的活动；
            </div>
            <div className="font-body">
                4.2.2.2 提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
            </div>
            <div className="font-body">
                4.2.2.3 明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
            </div>
            <div className="font-body">
                4.2.2.4 使用未经许可的数据或进入未经许可的服务器/账号；
            </div>
            <div className="font-body">
                4.2.2.5 未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
            </div>
            <div className="font-body">
                4.2.2.6 未经许可，企图探查、扫描、测试本平台或网络的弱点或其它实施破坏网络安全的行为；
            </div>
            <div className="font-body">
                4.2.2.7 企图干涉、破坏本平台或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
            </div>
            <div className="font-body">
                4.2.2.8 伪造TCP/IP数据包名称或部分名称；
            </div>
            <div className="font-body">
                4.2.2.9 利用本平台上传任何病毒、木马，或者蠕虫等危害网络健康的内容；
            </div>
            <div className="font-body">
                4.2.2.10 对本平台和材料进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）或者以其他方式尝试发现本平台或网站的源代码；未经许可修改、禁用本平台或网站的任何功能或创建基于软件和网站的衍生作品。去除本平台或网站上的任何所有权声明或标签，或将其他软件与本平台合并。
            </div>
            <div className="font-body">
                4.2.3 违反本协议而使用本平台及相关服务；
            </div>
            <div className="font-body">
                4.2.4 采取或企图采取任何侵犯寰渺或其他第三方合法权利的行动；
            </div>
            <div className="font-body">
                4.2.5 违反任何适用的法律。
            </div>
            <div className="font-body">
                4.3 如有合法证据证明或寰渺合理判断您存在违反上述条款的情形，寰渺可采取冻结、注销账号、停止提供本平台服务等措施而无需向您事先通知，并根据本协议第8条向您追究违约责任。如给寰渺造成损失的，您还应承担一切法律责任并赔偿全部损失。
            </div>
            <div className="font-headline">
                5 所有权:
            </div>
            <div className="font-body">
                5.1 寰渺保留对材料和以下各项内容的完全的、不可分割的权利，包括但不限于所有权、知识产权和/或其他合法权益：本平台、产品、服务、网站及其所有元素，包括但不限于所有内容、数据、技术、软件、代码、用户界面、商标、logo等商业标识以及与其相关的任何衍生作品。
            </div>
            <div className="font-body">
                5.2 材料和上述所有内容应受《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》、《世界知识产权组织版权条约》和其他知识产权法律法规的保护。除了根据本协议明确授予用户的权利外，本协议并未且不旨在明示或暗示对材料和上述所有内容创设任何归属于用户的权利和/或许可。
            </div>
            <div className="font-body">
                5.3 未经寰渺事先书面同意，材料和上述所有内容均不得在任何媒体直接或间接地发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。由于材料和上述所有内容在上传、发布、生成或传输的过程中产生的延误、不准确、错误和遗漏而导致的任何损害，寰渺不对此进行赔偿，亦不以任何形式向用户或任何第三方承担任何责任。
            </div>
            <div className="font-body">
                5.4 寰渺为提供本平台服务而可能使用的任何软件（包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利，均属于该软件的著作权人。未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble），或以其他方式尝试发现其源代码，以及实施任何涉嫌侵害著作权的行为。
            </div>
            <div className="font-body">
                5.5 您理解并同意：为持续改善本平台为您提供的各项服务，除非有相反证明，您使用本平台上传、发布、生成或传输内容，即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予寰渺及其关联方的全部产品或服务对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；对您的上传、发布、生成的内容进行商业开发的权利；通过有线或无线网络向计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑等）、手持数字影音播放设备、电视接收设备（模拟信号接收设备、数字信号接收设备、数字电视、IPTV、电视机机顶盒、视频投影仪、车载电视、互联网电视/OTT终端、智能电视等带互联网接入功能的播放设备等）等提供信息的下载、点播、数据传输、移动视频业务（包括但不限于SMS、MMS、WAP、IVR、Streaming、手机视频等无线服务）及其相关宣传和推广等服务的权利；以及再授权给其他第三方以上述方式使用的权利。上述授权为普通授权许可。寰渺有权在获得上述授权后，以各种合法方式使用您上传、发布、生成或传输的内容供寰渺及其关联方进行内部使用（包括但不限于内部培训、对本平台及其人工智能大模型进行各种训练）。寰渺将严格遵守法律法规保护您的个人信息，在合理的安全水平内采取各种安全保护措施以保障您的个人信息的安全，以防止信息的泄露、违法收集和使用，具体请见《隐私政策》。
            </div>
            <div className="font-headline">
                6 陈述和保证
            </div>
            <div className="font-body">
                6.1 对于您使用本平台上传、发布或传输的任何内容，用户在此向寰渺作出如下声明、保证并承诺:
            </div>
            <div className="font-body">
                6.1.1 用户拥有所有必要的权利、许可、同意和权限，可根据本协议上传、发布、或传输内容至本平台；
            </div>
            <div className="font-body">
                6.1.2 用户不得侵犯或盗用寰渺或任何第三方的任何知识产权或任何其他权利，也不得违反任何适用的法律、法规;
            </div>
            <div className="font-body">
                6.1.3 用户对其上传、发布或传输的内容负全部责任，包括但不限于其合法性、准确性、真实性、完整性和合规性;
            </div>
            <div className="font-body">
                6.1.4 如用户上传、发布或传输的内容涉嫌侵犯寰渺或任何第三方的权利或违反任何法律、法规，用户应独立承担一切法律责任，并根据本协议第8条就由此引起的任何损失向寰渺提供赔偿。
            </div>
            <div className="font-headline">
                7 免责声明和责任限制
            </div>
            <div className="font-body">
                7.1 鉴于网络服务的特殊性，您理解：寰渺有权随时变更、中断或终止部分或全部的本平台、服务。如变更、中断或终止的本平台服务属于免费部分，则本平台无需事先通知用户，亦无需对用户或任何第三方承担任何责任。
            </div>
            <div className="font-body">
                7.2 您理解：本平台需要定期或不定期地进行检修或者维护，如因此类情况而造成付费服务在合理时间内的中断，寰渺无需为此承担任何责任，但寰渺应尽可能事先进行通知用户。
            </div>
            <div className="font-body">
                7.3 本平台可在任何时候为任何原因变更本服务或删除其部分功能。寰渺可在任何时候取消或终止用户对本平台的使用，且不需要理由或通知用户。一旦取消，用户使用本平台的权利立即终止，用户在本服务中储存的任何信息可能无法恢复。
            </div>
            <div className="font-body">
                7.4 本平台不保证（包括但不限于）：
            </div>
            <div className="font-body">
                7.4.1 适合用户的使用要求；
            </div>
            <div className="font-body">
                7.4.2 不受干扰，及时、安全、可靠或不出现错误，用户经由本平台取得的任何产品、服务或其他材料符合用户的期望；
            </div>
            <div className="font-body">
                7.4.3 本平台中提供的任何信息是准确和完整的，或者本平台和材料是没有任何缺陷，错误或类似问题的。用户在此承认并同意：使用过程中可能会遇到限制、延迟和其他潜在的问题（“问题”），在任何情况下，寰渺均不承担由此类问题引起的任何损失。
            </div>
            <div className="font-body">
                7.5 由于您使用或出于各种原因无法使用本平台而造成的任何利润、商业信誉、资料损失或其他有形或无形损失，本平台不承担任何直接、间接的赔偿责任。
            </div>
            <div className="font-body">
                7.6 由于您授权第三方（包括第三方应用）使用本平台及相关服务所导致的任何纠纷或损失，应由您自行承担。
            </div>
            <div className="font-body">
                7.7 您理解并同意，基于用户体验或本平台运营安全、平台规则要求及健康发展等综合因素，本平台有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况终止向存有包括但不限于以下情形的用户提供本平台：
            </div>
            <div className="font-body">
                7.7.1 违反法律法规或本协议规定的；
            </div>
            <div className="font-body">
                7.7.2 影响使用者体验的；
            </div>
            <div className="font-body">
                7.7.3 存在安全隐患的；
            </div>
            <div className="font-body">
                7.7.4 违背本平台运营原则，或不符合本平台其他管理要求的。
            </div>
            <div className="font-body">
                7.8 责任限制：您同意并认可，除非另有法律的强制性规定，即使在本协议未约定的情况下，您向寰渺追索的最高累计赔偿或补偿金额应以您已向寰渺支付的全部金额为限，若您免费使用本平台，则寰渺不承担任何赔偿责任。
            </div>
            <div className="font-headline">
                8 违约责任
            </div>
            <div className="font-body">
                8.1 用户对本协议所有条款以及相关法律法规的任何违反，均将被视为用户对本协议的实质性违反，寰渺保留自行决定立即终止用户访问本平台的权利。此外，寰渺保留因用户实质性违反本协议而向用户追讨损失的权利。
            </div>
            <div className="font-body">
                8.2 赔偿责任：
            </div>
            <div className="font-body">
                8.2.1 如您的违约、侵权行为使寰渺和/或其关联公司、第三方支付公司遭受损失，您应赔偿寰渺和/或其关联公司、第三方支付公司的上述全部损失。
            </div>
            <div className="font-body">
                8.2.2 本协议所称的“损失”，包含直接经济损失与间接损失，包括但不限于寰渺因此而支付的赔偿金、商誉损失、预期可得利益、财产保全费、律师费、诉讼费等。
            </div>
            <div className="font-body">
                8.3 违约、侵权处理措施：
            </div>
            <div className="font-body">
                8.3.1 您在本平台上发布的信息构成违约或侵权的，本平台可在不违反相关法律法规的情况下，立即对相应信息进行删除、屏蔽处理。
            </div>
            <div className="font-body">
                8.3.2 您在本平台上实施的行为，或虽未实施但对本平台及其用户产生影响的行为构成违约或侵权的，本平台可依据相应规则要求您：限期纠正违规或违约、侵权行为；屏蔽或限制您使用平台的部分功能；对违法违规侵权内容进行屏蔽或删除；中止向您提供部分或全部服务等其他合理处理措施。如构成实质性违约的，本平台可单方解除本协议，终止向您提供服务。
            </div>
            <div className="font-headline">
                9 终止
            </div>
            <div className="font-body">
                9.1 您有权通过以下任一方式终止本协议：
            </div>
            <div className="font-body">
                9.1.1 注销账号；
            </div>
            <div className="font-body">
                9.1.2 您拒绝接受本平台修订的新协议并停止使用本平台及相关服务的。
            </div>
            <div className="font-body">
                9.2 当出现下列情况之一时，寰渺可以通过注销账号的方式终止本协议：
            </div>
            <div className="font-body">
                9.2.1 在您实质性违反本协议相关规定时，寰渺依据违约条款终止本协议。寰渺将在终止服务时通知您。但如您在本协议终止后，再一次直接或间接以他人名义注册为寰渺用户的，寰渺有权再次单方面终止向您提供服务；
            </div>
            <div className="font-body">
                9.2.2 如寰渺通过您提供的信息与您联系时，发现您在注册时填写的电子邮箱或手机等已不存在或无法接收信息的，经寰渺以您在注册时填写的其他联系方式通知您更改，而您在三个工作日内仍未能提供，或寰渺无法根据您注册时预留的信息联系到您的；
            </div>
            <div className="font-body">
                9.2.3 经寰渺发现您注册信息中主要内容是虚假的；
            </div>
            <div className="font-body">
                9.2.4 本协议终止或更新时，您明示不愿接受新的服务协议的；
            </div>
            <div className="font-body">
                9.2.5 您在寰渺平台有发布违法信息、侵犯他人合法权益或其他严重违法行为的；
            </div>
            <div className="font-body">
                9.2.6 除上述情形外，因您多次违反寰渺平台规则相关规定且情节严重，寰渺依据平台规则对账号予以查封的；
            </div>
            <div className="font-body">
                9.2.7 其它寰渺认为需终止服务的情况。
            </div>
            <div className="font-body">
                9.3 协议终止后的处理本协议终止后，除法律有明确规定外，寰渺无义务向您或您指定的第三方披露账号中的任何信息。本协议终止后，寰渺仍享有下列权利：
            </div>
            <div className="font-body">
                9.3.1 在遵守《隐私政策》的前提下，继续保存您留存于寰渺平台的各类信息；
            </div>
            <div className="font-body">
                9.3.2 对于您过往的违约行为，寰渺仍可依据本协议第8条向您追究违约责任。
            </div>
            <div className="font-headline">
                10 法律适用和争议解决
            </div>
            <div className="font-body">
                10.1 本协议之签订与执行（包括生效、履行、解释、修订与补充、终止与争议解决）均适用中华人民共和国大陆地区法律。
            </div>
            <div className="font-body">
                10.2 凡因履行本协议所发生的争议，首先应当由您与寰渺友好协商解决，不能协商解决的，可向寰渺科技（上海）有限公司所在地人民法院提起诉讼。
            </div>
            <div className="font-headline">
                11 联系方式和通知
            </div>
            <div className="font-body">
                11.1 如您在使用本平台时有任何问题、投诉、举报、意见或建议，或对本协议有任何疑议，可通过contact@cellverse.ai与寰渺联系。
            </div>
            <div className="font-body">
                11.2 如您认为通过本平台生成的内容可能涉嫌侵害您或第三方的合法权利，应及时向寰渺反映，并提供书面的身份证明、权属证明及详细的侵权情况证明，寰渺在收到上述文件后，将会依法尽快断开相关链接内容。
            </div>
            <div className="font-headline">
                12 投诉
            </div>
            <div className="font-body">
                12.1 寰渺十分尊重知识产权。如果权利人发现他人侵犯了权利人的合法权益，权利人可以通过本协议第11.1条显示的方式进行投诉，邮件标题为“权利通知”，并详细按照以下法律要求描述涉嫌侵权行为，以便寰渺可以在收到权利通知后，删除涉嫌侵权的内容或断开与涉嫌侵权内容的链接，并有权视情节，注销侵权者的账号。
            </div>
            <div className="font-body">
                12.2 因投诉不实给寰渺或第三方造成损失的，用户应承担法律责任。
            </div>
            <div className="font-body">
                12.3 权利通知务必包括以下信息：
            </div>
            <div className="font-body">
                12.3.1 权利人的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件；
            </div>
            <div className="font-body">
                12.3.2 权利人享有权利的权属证明材料；
            </div>
            <div className="font-body">
                12.3.3 涉嫌侵权内容在信息网络上的位置（如指明您举报的含有侵权内容的出处，即：指网页地址或网页内的位置），以便寰渺与您举报的含有侵权内容的网页的所有权人/管理人联系；
            </div>
            <div className="font-body">
                12.3.4 请权利人充分准确的描述哪些合法权益被侵害，并提供构成侵犯权利人合法权益的初步证明材料；
            </div>
            <div className="font-body">
                12.3.5 请在权利通知中加入如下关于通知内容真实性的声明：“本人为所投诉内容的合法权利人；在我举报的访问地址下登载的内容侵犯了本人相应的合法权益。本人确认：如果本权利通知内容不完全属实，本人将承担由此产生的一切法律责任。”；
            </div>
            <div className="font-body">
                12.3.6 请在权利通知中加入以下陈述：“我保证，本通知中所述信息是充分、真实、准确的，我是所投诉内容的合法权利人”；
            </div>
            <div className="font-body">
                12.4 请您签署该文件，如果您是依法成立的机构或组织，请您加盖公章。
            </div>
            <div className="font-body">
                12.5 请您注意：如果您的权利通知失实，您将承担对由此造成的全部损失。
            </div>
            <div className="font-body">
                12.6 寰渺收到权利通知后，会同时将通知书转送被删除或被断开链接的内容提供者，但内容提供者认为被删除或被断开链接的内容未侵犯他人权利的，可以依法向contact@cellverse.ai发出恢复被删除的内容或恢复链接的反通知，邮件标题为“关于被删除内容或链接不侵权的通知”，反通知应包含以下内容：
            </div>
            <div className="font-body">
                12.6.1 请指明被寰渺删除或断开链接的内容及其在信息网络上的位置（如指明网页地址或网页内的位置）；
            </div>
            <div className="font-body">
                12.6.2 被删除内容的合法性证明，包括但不限于您对被删除或被断开链接的内容拥有合法权益的权属证明或您可以合法使用该作品或该内容的证明；
            </div>
            <div className="font-body">
                12.6.3 请提供具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件；
            </div>
            <div className="font-body">
                12.6.4 请在反通知中加入如下关于反通知内容真实性的声明：“人为该作品/该内容的合法权利人；我有权或已获授权提供或刊登上述被删除或被断开链接的内容。本人确认：如果本“反通知”内容不完全属实，本人将承担由此产生的一切法律责任。”；
            </div>
            <div className="font-body">
                12.6.5 请在反通知中加入以下陈述：“我保证，本反通知中所述信息是充分、真实、准确的，我是该作品/该内容的合法权利人”；
            </div>
            <div className="font-body">
                12.6.6 请您签署该文件，如果您是依法成立的机构或组织，请您加盖公章。
            </div>
            <div className="font-headline">
                13 其他
            </div>
            <div className="font-body">
                13.1 本协议任一条款终止或被视同终止、无效或不可执行，该条款应当被视为本协议的可拆分条款，并不影响其余条款的有效性及可执行性。
            </div>
            <div className="font-body">
                13.2 除非寰渺以书面形式做出表示，否则在任何情况下寰渺做出的任何行为都不应被理解为放弃任何权利的意思表示。
            </div>
            <div className="font-body">
                13.3 用户承认并同意：经事先书面通知用户，寰渺有权根据法律法规变化、保护消费者权益需要等合理合法情况，不定时地修订本协议，如本协议的任何变更以本平台最新公布的内容为准。经修订的新协议一经公布，立即自动生效，对新协议生效之后成功注册的用户发生法律效力。对于新协议生效之前注册的用户，若用户在新协议生效后继续使用本平台提供的各项服务，则表明用户已充分阅读并同意遵守新协议。若您拒绝接受新协议，您必须放弃使用本平台及相关服务（包括但不限于使用用户名和密码登录本平台、访问或使用服务等）。
            </div>
        </Box>
    );
}