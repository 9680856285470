import React from 'react';
import AF from './img/AF.jpg';
import AFM from './img/AFM.jpg';
import ESM from './img/ESM.jpg';
import LIGANDMPNN from './img/LigandMPNN.jpg';
import RFAA from './img/RFAA.png';
import { Box } from '@mui/material';

const Tag = ({ text }) => {
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(81, 125, 255, 0.16)',
                color: 'rgba(70, 77, 209, 1)',
                fontSize: '12px',
                fontWeight: '600',
                padding: '2px 8px',
                borderRadius: '10px',
                marginRight: '8px',
            }}
        >
            {text}
        </Box>
    );
}

export default function Card({ type, choose }) {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                margin: '0 20px',
                cursor: 'pointer',
                width: '300px',
                // '&:hover': {
                //     boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                // },
            }}
        >
            <Box
                sx={{
                    border: type === choose ? '8.96px solid #517DFF' : '',
                    width: '300px',
                    height: '300px',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={type === 'AF' ? AF
                        : type === 'AFM' ? AFM
                            : type === 'ESM' ? ESM
                                : type === 'LigandMPNN' ? LIGANDMPNN
                                    : RFAA}
                    alt={type}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>

            {/* model name */}
            <Box
                sx={{
                    marginTop: '16px',
                    fontSize: '20.37px',
                    fontWeight: '600',
                    color: '#000000',
                    textAlign: 'left'
                }}
            >
                {
                    type === 'AF' ? 'AlphaFold2 (Single)'
                        : type === 'AFM' ? 'AlphaFold2 (-Multimer)'
                            : type === 'ESM' ? 'ESMFold'
                                : type === 'LigandMPNN' ? 'LigandMPNN'
                                    : ''
                }
            </Box>

            {/* tags */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '16px',
                }}
            >
                {
                    type === 'AF' && <Tag text='Protein Prediction' />
                }
                {
                    type === 'AFM' && <Tag text='Protein Prediction' />
                }
                {
                    type === 'AFM' && <Tag text='High Quality' />
                }
                {
                    type === 'ESM' && <Tag text='Protein Prediction' />
                }
                {
                    type === 'ESM' && <Tag text='Protein Language Model' />
                }
                {
                    type === 'LigandMPNN' && <Tag text='Sequence Design' />
                }
            </Box>

            {/* model description */}
            <Box
                sx={{
                    marginTop: '8px',
                    fontSize: '16.3px',
                    fontWeight: '400',
                    color: '#919191',
                    textAlign: 'left'
                }}
            >
                {
                    type === 'AF' ? 'A fast verison of AlphaFold2 for single protein structure prediction without using MSA database.'
                        : type === 'AFM' ? 'Accurately predict protein structure and even protein-protein complex with MSA database.'
                            : type === 'ESM' ? 'Protein language model-based 3D structure prediction at the atomic level.'
                                : type === 'LigandMPNN' ? 'Deep learning-based protein sequence design method that allows explicit modeling of small molecule, nucleotide, metal, and other atomic contexts.'
                                    : ''
                }
            </Box>

        </Box>
    );
}