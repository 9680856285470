import Box from '@mui/material/Box';

export default function Privacy() {
    return (
        <Box sx={{ backgroundColor: '#fff', width: '100%', padding: '20px' }}>

            <div className="font-title">
                寰渺AI蛋白质平台隐私政策
            </div>

            <div className="font-label">
                本隐私政策首次发布于：2024年4月12日
                <br />
                更新于:2024年4月12日
            </div>

            <div className="font-body">
                欢迎您使用寰渺AI蛋白质平台（下称“本平台”）及相关服务！
            </div>

            <div className="font-body">
                本平台由寰渺科技（上海）有限公司（下称“寰渺”或“我们”）负责运营。本隐私政策与《用户服务协议》共同构成不可分割的整体，具有同等法律效力，适用于您在本平台的全部活动。如无特别说明，本隐私政策中的所有定义与《用户服务协议》一致。
            </div>
            <div className="font-body">
                寰渺非常重视用户的个人信息和隐私保护，我们致力于遵守《中华人民共和国个人信息保护法》及相关法律法规，并让您了解我们收集哪些关于您的数据，以及我们如何收集、使用、分享、储存和管理这些信息。
            </div>

            <div className="font-label font-bf">
                【特别提示】
            </div>
            <div className="font-body">
                一、请您在开始使用本平台前务必审慎阅读本隐私政策，充分理解各条款内容，特别是免除或者限制责任的条款（该等条款通常含有“不负任何责任”、“无义务”等词汇）、其他限制用户权利的条款（该等条款通常含有“不得”等词汇）、法律适用和争议解决条款。该等条款将以粗体标识，您应重点阅读。
            </div>
            <div className="font-body">
                二、如您不同意本隐私政策的条款，请立即停止注册并退出平台账号登录，同时停止使用平台相关服务。您一旦点击“接受”、“同意”或其他类似含义的按键，或者成功注册账号、开始使用及/或继续使用本平台或者相关服务，即表明您已阅读并充分理解本隐私政策之内容，并且已同意我们按照本隐私政策（含更新版本）收集、使用、分享、储存和管理您的相关信息。
            </div>
            <div className="font-body">
                三、<span className='font-bf'>寰渺有权根据法律法规变化、保护消费者权益需要等合理合法情况，不定时地修订本隐私政策，如本隐私政策的任何变更以本平台最新公布的内容为准。经修订的隐私政策一经公布，立即自动生效，对新隐私政策生效之后成功注册的用户发生法律效力。</span>对于新隐私政策生效之前注册的用户，若用户在新<span className="font-bf">隐私政策</span>生效后继续使用本平台提供的各项服务，则表明用户已充分阅读并同意遵守新隐私政策。若您拒绝接受新隐私政策，您必须放弃使用本平台及相关服务（包括但不限于使用账号和密码登录本平台、访问或使用服务等）。
            </div>
            <div className="font-body">
                四、您需特别注意，由于本平台创作服务生成的所有内容都是由人工智能模型生成，虽已经过不断的自动及人工敏感数据过滤，但仍不排除其中部分信息具有瑕疵、不合理或引人不适的情形，您对使用本平台服务所生成的所有内容负有审慎审核的义务，如生成内容涉嫌违法违规、敏感有害、违反公序良俗等情况，您不得通过截图、下载、拍照、录像、屏幕捕捉等方式留存相关图片，并不得对此进行发送、传播或商业化使用。否则，因您使用生成内容进行任何违法或不当的活动等被追责所产生的所有责任，由您自行承担。
            </div>
            <div className="font-body">
                五、如您对本隐私政策内容有任何疑问、投诉、意见或建议，您可按照《用户服务协议》显示的联系方式与我们联系。
            </div>


            <div className="font-headline">
                1 个人信息
            </div>

            <div className="font-body">
                <span className='font-bf'>1.1 定义</span>：您的“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括但不限于：自然人的基本资料、个人身份信息、个人生物识别信息、网络身份标识信息、个人财产信息、通讯录信息、个人上网记录、个人位置信息、设备信息及个人用户标识。我们实际具体收集的个人信息种类以下文描述为准。
            </div>
            <div className="font-body">
                <span className='font-bf'>1.2 收集</span>：在您使用本平台及相关服务的过程中，我们仅会在非常必要的情况下和非常有限的限度内收集您的个人信息，并且这些信息仅用于向您提供本平台及相关服务、优化我们的本平台及相关服务，以及保障您的账号安全。具体而言，我们将按照如下方式收集您主动提供或因为使用服务而产生的信息：
            </div>
            <div className="font-body">
                1.2.1 在您进行注册认证、发布信息、互动交流或使用本平台及相关服务时，基于法律要求或实现功能所必需，我们可能会收集您的用户名、手机号、头像等个人信息。除此之外，我们不会收集您的IMEI、IMSI、设备MAC地址、软件安装列表、位置、联系人、通话记录、日历、短信、本机电话号码、图片、音视频等不必要的个人信息。
            </div>
            <div className="font-body">
                1.2.2 若您通过第三方账号（如微信、QQ等）登录进入平台，您此时将授权我们获取您在第三方平台注册的公开信息（头像、昵称等），并在您同意本隐私政策后将您的第三方账号与您在平台的账号绑定，使您可以通过第三方账号直接登录并使用本平台及相关服务。
            </div>
            <div className="font-body">
                1.2.3 若本平台及相关服务涉及为您提供个性化的信息或服务，我们可能会收集您关注、收藏、搜索、浏览偏好等您的操作、使用行为信息；以及您反馈、发布、点赞、评论等您主动提供的信息；
            </div>
            <div className="font-body">
                1.2.4 当您使用本平台及相关服务时，我们可能会收集您所使用的相关设备以及网络环境等信息，以便为您提供持续稳定的网络运营支持，同时有助于我们不断改善自身或服务，从而使您在使用本平台及相关服务过程中获得最优体验。
            </div>
            <div className="font-body">
                1.2.5 若您在线购买本平台及相关服务，根据需要您可提供姓名、通信地址、联系方式、银行账号以及其他财务信息。
            </div>
            <div className="font-body">
                1.2.6 请您了解，随着我们业务的发展，可能会对我们的平台和服务有所调整变化。原则上，当新功能或服务与个性化推荐、发布信息、互动交流、搜索查询、注册认证等场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。
            </div>
            <div className="font-body">
                1.2.7 您充分知晓：在以下情形下，我们收集、使用个人信息无需征得您的授权同意：
            </div>
            <div className="font-body">
                1.2.7.1 与国家安全、国防安全有关的；
            </div>
            <div className="font-body">
                1.2.7.2 与公共安全、公共卫生、重大公共利益有关的；
            </div>
            <div className="font-body">
                1.2.7.3 与犯罪侦查、起诉、审判和判决执行等有关的；
            </div>
            <div className="font-body">
                1.2.7.4 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </div>
            <div className="font-body">
                1.2.7.5 所收集的个人信息是个人信息主体自行向社会公众公开的；
            </div>
            <div className="font-body">
                1.2.7.6 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </div>
            <div className="font-body">
                1.2.7.7 根据您的要求签订合同所必需的；
            </div>
            <div className="font-body">
                1.2.7.8 用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；
            </div>
            <div className="font-body">
                1.2.7.9 法律法规规定的其他情形。
            </div>
            <div className="font-body">
                <span className='font-bf'>1.3 第三方向您收集个人信息</span>：我们可能会与第三方服务提供商合作，在服务中接入第三方服务（例如投放广告、链接或者其他形式的推广内容）。我们不会将您提供给我们的个人信息（如手机号码、昵称、头像等）与第三方服务商共享，也不会代表第三方服务商为其收集您的个人信息。通过本平台及相关服务接入的第三方服务商可能有自己的隐私政策，其可能不受本隐私政策的约束。第三方服务商可能会根据其自己的隐私政策收集为您提供服务所需的个人信息。我们将尽商业上合理的努力要求第三方服务商在处理您的个人信息时遵守相关法律法规，尽力要求其采取相关的保密和安全措施。
            </div>
            <div className="font-body font-bf">
                1.4 使用您的个人信息：
            </div>
            <div className="font-body">
                1.4.1 我们会根据本隐私政策的约定并为实现本平台及相关服务功能对所收集的个人信息进行使用。
            </div>
            <div className="font-body">
                1.4.2 请您注意，对于您在使用本平台及相关服务时所提供的所有个人信息，除非您删除或通过相关设置拒绝我们收集，否则您将在使用期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。
            </div>
            <div className="font-body">
                1.4.3 我们会对本平台及相关服务的使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示本平台及相关服务的整体使用趋势。但这些统计信息不会包含您的任何身份识别信息。
            </div>
            <div className="font-body">
                1.4.4 当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，我们会主动事先征求您的明示同意。
            </div>
            <div className="font-body">
                <span className="font-bf">1.5 使用Cookie和同类技术</span>：为了更好地向您提供本平台及相关服务，我们可能会使用自动数据收集工具。借助这些技术，为您提供流畅、优质、个性化的用户体验。我们不会将上述技术用于本隐私政策所述目的之外的任何用途。请您理解，我们的某些服务只能通过使用上述技术才可得到实现。如您的设备或相关程序允许，您可以通过改变设置，或通过访问提供商的网页，来关闭或删除上述技术。但这一举动可能会影响您使用本平台及相关服务。
            </div>
            <div className="font-body font-bf">
                1.6 共享、转让、公开披露您的个人信息：
            </div>
            <div className="font-body">
                1.6.1 共享：我们将严格遵守相关法律法规，对您的个人信息予以保密。除以下情况外，我们不会向其他人共享您的个人信息：
            </div>
            <div className="font-body">
                1.6.1.1 事先获得您明确的同意或授权；
            </div>
            <div className="font-body">
                1.6.1.2 根据适用的法律法规规定，或基于司法或行政主管部门的强制性要求进行提供；
            </div>
            <div className="font-body">
                1.6.1.3 在法律法规允许的范围内，为维护您或其他用户或其他个人的生命、财产等合法权益或社会公共利益而有必要提供；
            </div>
            <div className="font-body">
                1.6.1.4 应您的监护人的合法要求而提供您的信息；
            </div>
            <div className="font-body">
                1.6.1.5 根据与您签署的相关协议或其他的法律文件约定而提供；
            </div>
            <div className="font-body">
                1.6.1.6 与本隐私政策第1.3条所述第三方进行共享：您在使用本平台及相关服务的过程中使用本隐私政策第1.3条所述的第三方服务时，我们与第三方的共享规则将遵循本隐私政策第1.3条项下相关约定；
            </div>
            <div className="font-body">
                1.6.1.7 我们可能会基于您的相应授权将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策所述目的之约束。我们的关联方如要改变个人信息的处理目的，将适时向您征得明示同意
            </div>
            <div className="font-body">
                1.6.2 转让：我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            </div>
            <div className="font-body">
                1.6.2.1 事先得您明确的同意或授权；
            </div>
            <div className="font-body">
                1.6.2.2 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求而必须进行提供；
            </div>
            <div className="font-body">
                1.6.2.3 根据与您签署的相关协议或其他的法律文件约定而提供；
            </div>
            <div className="font-body">
                1.6.2.4 在涉及收购、兼并、破产清算、重组等变更时，如涉及个人信息转让，我们会要求新的持有您个人信息的公司或组织继续履行本隐私政策项下的责任和义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。
            </div>
            <div className="font-body">
                1.6.3 公开披露：我们仅会在以下情况下，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
            </div>
            <div className="font-body">
                1.6.3.1 根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
            </div>
            <div className="font-body">
                1.6.3.2 根据法律、法规的要求、行政或司法机关的强制性要求，我们可能会公开披露您的个人信息。当我们收到上述披露请求时，我们会依法要求请求方出具相关法律文件，如传票或调查函等。我们会慎重审查每一披露请求，以确保该等披露请求符合相关法律规定。在法律法规许可的前提下，我们会对包含披露信息的文件进行加密保护。
            </div>
            <div className="font-body font-bf">
                1.7 保护您的个人信息
            </div>
            <div className="font-body">
                1.7.1 我们非常重视个人信息安全，并会采取一切合理可行的措施，持续保护您的个人信息，以防其他人在未经授权的情况下访问、篡改或披露我们收集的您的个人信息。
            </div>
            <div className="font-body">
                1.7.2 我们会采取一切合理可行的措施，确保未收集与本平台及相关服务无关的个人信息。
            </div>
            <div className="font-body">
                1.7.3 请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息100%的安全。您需要了解，您接入本平台所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            </div>
            <div className="font-body font-bf">
                1.8 保存您的个人信息
            </div>
            <div className="font-body">
                1.8.1 在您使用本平台及相关服务期间，我们会持续保存用户的个人信息。我们承诺将是为了实现本平台及相关服务目的所必需的最短期限。在超出上述期限后，我们会对您的相关信息进行删除或匿名化处理。
            </div>
            <div className="font-body">
                1.8.2 您的个人信息将全部被存储于中华人民共和国境内。目前我们不存在向境外提供个人信息的场景。如将来涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。若您是未满18周岁的未成年人，您应在您的监护人监护、指导下并获得监护人同意的情况下，认真阅读并同意本隐私政策后，方可使用本平台产品及相关服务。
            </div>
            <div className="font-body font-bf">
                1.9 管理您的个人信息
            </div>
            <div className="font-body">
                1.9.1 您可以通过登录您在本平台注册的账号来查看您提交给我们的个人信息。
            </div>
            <div className="font-body">
                1.9.2 为保证您正确身份验证，请及时更新相关信息，以确保它们是准确、最新且完整的，在此过程中如有任何问题，请随时与我们联系。
            </div>
            <div className="font-body">
                1.9.3 在您主动注销账号后，如果您提出书面申请，我们将根据您的意愿和法律规定将您的个人信息删除或进行匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）。
            </div>
            <div className="font-headline">
                2 未成年人隐私保护
            </div>
            <div className="font-body">
                2.1 如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用本平台及相关服务或向我们提供信息。
            </div>
            <div className="font-body">
                2.2 对于经父母或监护人同意使用我们的本平台及相关服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用、共享或披露该信息。
            </div>
            <div className="font-headline">
                3 本隐私政策的修订和通知
            </div>
            <div className="font-body">
                3.1 由于服务内容、联络方式、法律和监管政策要求等变化，我们可能会适时对本隐私政策进行修订。当隐私政策发生变更时，我们会在我们的平台发布或向您提供的电子邮箱地址发送更新后的版本。为了您能及时接收到通知，建议您在电子邮箱地址变动时及时更新账号信息或通知我们。
            </div>
            <div className="font-body">
                3.2 若您继续使用本平台及相关服务，即表示您充分阅读、理解并同意受经修订的本隐私政策的约束。如果您不同意修改的内容，请您在本隐私政策修改后停止使用我们的软件或服务的任何部分或全部，如您继续使用我们的软件或服务，有必要对最新的隐私政策进行仔细阅读和重新确认，当发生相关争议时，以最新的隐私政策为准。
            </div>
            <div className="font-headline">
                4 其他
            </div>
            <div className="font-body">
                4.1 本隐私政策未尽事宜，应当以本平台公布的最新修订的《用户服务协议》为准。
            </div>

        </Box>
    );
}