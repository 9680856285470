import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
// import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import { Divider } from '@mui/material';
// eslint-disable-next-line
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

// eslint-disable-next-line
const PDFDocument = ({ reportData, reportOpen }) => (
    <Document>
        {
            reportOpen ?
                <>
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <Text style={{ fontSize: '64px' }}>Report</Text>
                            <Text style={{ fontSize: '12px' }}>{reportData.filename} - {reportData.time}</Text>
                            {
                                reportData.data['plddt'] ?
                                    <>
                                        <Text style={{ fontSize: '16px' }}>
                                            Plot of the per residue pLDDT for the model(s)
                                        </Text>
                                        <Image source={{ uri: `data:image/png;base64,${reportData.data['plddt']}` }} style={{ width: '100%', height: 'auto' }} />
                                    </>
                                    :
                                    <></>
                            }
                        </View>

                    </Page>
                    {
                        reportData.data['pae'] ?
                            <Page size="A4" style={styles.page}>
                                {
                                    reportOpen ?
                                        <View style={styles.section}>
                                            <Text style={{ fontSize: '16px' }}>
                                                Plot of the PAE(predicted aligned error) for the model(s)
                                            </Text>
                                            <Image source={{ uri: `data:image/png;base64,${reportData.data['pae']}` }} style={{ width: '100%', height: 'auto' }} />
                                        </View>
                                        :
                                        <></>
                                }
                            </Page>
                            :
                            <></>
                    }
                    {
                        reportData.data['msa_coverage'] ?
                            <Page size="A4" style={styles.page}>
                                {
                                    reportOpen ?
                                        <View style={styles.section}>
                                            <Text style={{ fontSize: '16px' }}>
                                                Plots of the MSA Coverage for the model(s)
                                            </Text>
                                            {Array.isArray(reportData.data['msa_coverage']) ? (
                                                reportData.data['msa_coverage'].map((image, index) => (
                                                    <Image source={{ uri: `data:image/png;base64,${image}` }} alt={`msa-${index}`} />
                                                ))
                                            ) : (
                                                <Image source={{ uri: `data:image/png;base64,${reportData.data['msa_coverage']}` }} alt="msa" />
                                            )}
                                        </View>
                                        :
                                        <></>
                                }
                            </Page>
                            :
                            <></>
                    }
                    :
                    <></>
                </>
                :
                <></>
        }
    </Document>
);


function TaskReport({
    setReportOpen,
    reportData,
    reportOpen
}) {
    return (
        <Box
            component={'div'}
            sx={{ width: '80%', maxHeight: '90%', backgroundColor: '#fff', padding: '50px', overflow: 'scroll', marginTop: '64px' }}
            onClick={(event) => { event.stopPropagation() }}
        >
            <Box sx={{ height: '30px' }}>
                <IconButton
                    aria-label="close"
                    sx={{ color: '#517DFF', float: 'right' }}
                    onClick={() => { setReportOpen(false) }}
                >
                    <ClearIcon />
                </IconButton>
            </Box>
            <Box
                component="div"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}
            >
                <Box>
                    <Box sx={{ color: '#517DFF', fontSize: '64px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '68px', letterSpacing: '0px' }}>
                        {reportData.filename} - Report
                    </Box>
                    <Box sx={{ color: '#5B5B5B', fontSize: '22px', fontFamily: 'Inter', fontWeight: '400', lineHeight: '26px', letterSpacing: '0px', marginTop: '20px' }}>
                        {reportData.time}
                    </Box>
                    {
                        reportData.row &&
                        <Box sx={{ color: '#5B5B5B', fontSize: '22px', fontFamily: 'Inter', fontWeight: '400', lineHeight: '26px', letterSpacing: '0px', marginTop: '20px' }}>
                            {Object.keys(reportData.row["options"]).map((key) => (
                                <div key={key} style={{
                                    backgroundColor: 'rgba(227, 234, 255, 1)',
                                    // color: '#000',
                                    fontSize: '14px',
                                    padding: '2px 10px',
                                    borderRadius: '20px',
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    margin: '5px 10px',
                                    color: 'rgba(81, 125, 255, 1)',
                                    // textOverflow: 'ellipsis'
                                }}>
                                    {key}: {reportData.row["options"][key]}
                                </div>
                            ))}
                        </Box>
                    }
                </Box>
                {/* <Box>
                    <PDFDownloadLink document={<PDFDocument reportData={reportData} reportOpen={reportOpen} />} fileName="report.pdf">
                        {({ blob, url, loading, error }) => (
                            <Button startIcon={<DownloadIcon />} variant='contained' sx={{ backgroundColor: '#517DFF' }}>
                                {loading ? 'Loading document...' : 'Download'}
                            </Button>
                        )}
                    </PDFDownloadLink>
                </Box> */}
            </Box>
            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
            {
                reportOpen ?
                    <Box component={'div'}>
                        {
                            reportData.data['plddt'] ?
                                <>
                                    <h2>
                                        Plot of the per residue pLDDT for the model(s)
                                    </h2>
                                    <div>
                                        Predicted Local Distance Difference Test (pLDDT) is a confidence score for structure predictions, indicating how reliable the predicted structure is for each residue.
                                        This plot visualizes the pLDDT scores across the predicted structure from a protein sequence, as generated by the model. The x-axis corresponds to the residue positions within the protein sequence, while the y-axis denotes the pLDDT scores. These scores are used to assess confidence in structural predictions, with higher values indicating greater reliability.
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`data:image/png;base64,${reportData.data['plddt']}`} alt="plddt" />
                                    </div>
                                </>
                                :
                                <></>
                        }

                        {
                            reportData.data['pae'] ?
                                <>
                                    <h2>
                                        Plot of the PAE(predicted aligned error) for the model(s)
                                    </h2>
                                    {/* <div>This is only avaliable for AlphaFold2 results with <code>model_preset</code> as <code>multimer</code> or <code>monomer_ptm</code>.</div> */}
                                    <div>
                                        PAE(predicted aligned error) estimates the expected positional error for each residue in a predicted protein structure if it were aligned to a corresponding residue in the true protein structure.
                                        The following series of heatmaps displays the PAE matrices for a set of Top5 protein structures based on the pLDDT score or the model confidence on these predicted structure, ranked from 0 to 4. Each heatmap represents a different predicted structure, with the axes possibly representing residue positions in the protein sequences.
                                        The color gradient in each heatmap—from deep green to bright white—quantifies the magnitude of expected positional error. Green indicates minimal positional errors, suggesting close correspondence if it were aligned to the reference, while white denotes significant discrepancies, indicating areas where the model predictions diverge from the reference.
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={`data:image/png;base64,${reportData.data['pae']}`} alt="pae" />
                                    </div>
                                </>
                                :
                                <></>
                        }
                        {
                            reportData.data['msa_coverage'] ?
                                <>
                                    <h2>
                                        Plots of the MSA Coverage for the model(s)
                                    </h2>
                                    {/* <div>This is only avaliable for AlphaFold2 results with <code>model_preset</code> as <code>multimer</code> or <code>monomer_ptm</code>.</div> */}
                                    <div>
                                        This plot represents the multiple sequence alignment (MSA) coverage across different positions of a protein sequence. The x-axis, labeled "Positions", reflects the length of the sequence segment considered in the analysis. The y-axis, labeled "Sequences", represents the number of sequences in the alignment at each position.
                                        The color gradient shown in the plot, as explained by the color bar on the right, indicates the sequence identity to a query sequence within the MSA, with the scale ranging from 0.0 (red) to 1.0 (purple). Colors transition from red, representing low identity to the input sequence, and to purple, indicating high identity. This visualization can be used to assess the extent of conservation across the protein sequence, highlighting areas with high variability (shown in warmer colors) and high conservation (shown in cooler colors).
                                    </div>
                                    {Array.isArray(reportData.data['msa_coverage']) ? (
                                        reportData.data['msa_coverage'].map((image, index) => (
                                            <div key={index}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={`data:image/png;base64,${image}`} alt={`msa-${index}`} />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <img src={`data:image/png;base64,${reportData.data['msa_coverage']}`} alt="msa" />
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <></>
                        }
                    </Box>
                    :
                    <></>
            }
            <Box
                sx={{
                    background: '#F9F9F9',
                    padding: '40px',
                    width: '100%',
                    fontFamily: 'monospace',  // 使用等宽字体
                    whiteSpace: 'pre-wrap',  // 保留换行和空格
                }}
            >
                {reportData.data &&
                    <pre>
                        <code>
                            {reportData.data['command_line']}
                        </code>
                    </pre>
                }
            </Box>
        </Box>
    );
}

export default TaskReport;