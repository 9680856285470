import React from 'react';
import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// eslint-disable-next-line
import { Button, CardActionArea, CardActions } from '@mui/material';
import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { FormControl } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import FilePresentIcon from '@mui/icons-material/FilePresent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Add from '@mui/icons-material/Add';
// import Divider from '@mui/material/Divider';
// import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
// import RemoveIcon from '@mui/icons-material/Remove';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Divider } from '@mui/material';
// import AF from './img/AF.jpg';
// import AFM from './img/AFM.jpg';
// import ESM from './img/ESM.jpg';
// import LIGANDMPNN from './img/LigandMPNN.jpg';
// import RFAA from './img/RFAA.png';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#517DFF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


function UserInputTable({ table, setTable }) {

    const handleDelete = (rowIndex) => {
        const newTable = table.filter((_, index) => index !== rowIndex);
        setTable(newTable);
    };

    return (
        <Box sx={{
            padding: '8.5px 14px',
            width: '100%',
            margin: '20px 0 0 0',
            minHeight: '50px'
        }}>
            Added residue amino acid bias
            {
                table.length > 0 ?
                    <Box>
                        {
                            table.map((row, rowIndex) => (
                                <Box key={rowIndex} sx={{ margin: '10px 0', padding: '16px 16px', overflow: 'auto', whiteSpace: 'nowrap', backgroundColor: '#F2F3FD' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleDelete(rowIndex)}
                                            sx={{
                                                backgroundColor: '#517DFF',
                                                ':hover': {
                                                    backgroundColor: '#3C6AC8',
                                                },
                                                marginRight: '20px',
                                                padding: '4px 40px'
                                            }}
                                        >
                                            Delete
                                        </Button>
                                        <Box>
                                            {row.name}:
                                            {Object.keys(row.value).map((cell, cellIndex) => (
                                                <Box component={'span'} key={cellIndex} sx={{ margin: '0 5px' }}>
                                                    "{cell}": {row.value[cell]},
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                    : <></>
            }
        </Box>
    );
}

function WeightNumberInput({ label, type }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <Box sx={{ marginRight: '10px', width: '40px', textAlign: 'right' }}>{label}: </Box>
            <FormControl>
                <TextField
                    id={type + label}
                    name={type + label}
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{ margin: '10px 0 20px 0', width: '80px', height: '30px', border: '1x solid #636bdd', borderRadius: '5px' }}
                    inputProps={{ step: "0.01" }}
                    defaultValue={0.0}
                />
            </FormControl>
        </Box>
    );
}

function CustomDialogContentText({ cardType }) {
    if (cardType === 'AF') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', backgroundColor: '#FFFFFF', padding: '30px 20px', color: '#000' }}>
                Current version: <b>2.3.2.</b> Upload one or more <b>*.fasta, *.fa</b> files to create a task. To predict protein complex's structure, please select multimer.
            </DialogContentText>
        );
    } else if (cardType === 'AFM') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', backgroundColor: '#FFFFFF', padding: '30px 20px', color: '#000' }}>
                Current version: <b>2.2.3.</b> Upload one or more <b>*.fasta, *.fa</b> files to create a task.
            </DialogContentText>
        );
    } else if (cardType === 'ESM') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', backgroundColor: '#FFFFFF', padding: '30px 20px', color: '#000' }}>
                Upload one or more <b>*.fasta, *.fa</b> files to create a task. num_recycles is a parameter to control the number of iterations. The default value is 4.
            </DialogContentText>
        );
    } else if (cardType === 'LigandMPNN') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', backgroundColor: '#FFFFFF', padding: '30px 20px', color: '#000' }}>
                Upload one <b>*.pdb</b> file to create a task. LigandMPNN is a deep learning model for protein sequence design.
            </DialogContentText>
        );
    } else if (cardType === 'RFAA') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', backgroundColor: '#FFFFFF', padding: '30px 20px', color: '#000' }}>
                Any Description?
            </DialogContentText>
        );
    }
}

function CustomCardChoice({ cardType, modelType, setModelType }) {
    if (cardType === 'AFM') {
        return (
            <>
                <FormControl sx={{
                    margin: '20px 0 10px 0',
                    width: '40%',
                }}>
                    <InputLabel>model</InputLabel>
                    <Select
                        id="model_preset"
                        name="model_preset"
                        defaultValue={'monomer'}
                        label="model"
                        size='small'
                    >
                        <MenuItem value={'monomer'}>monomer</MenuItem>
                        <MenuItem value={'multimer'}>multimer</MenuItem>
                        <MenuItem value={'monomer_ptm'}>monomer_ptm</MenuItem>
                        <MenuItem value={'monomer_single'}>monomer_single</MenuItem>
                        <MenuItem value={'monomer_ptm_single'}>monomer_ptm_single</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip title="Choose preset model configuration - the monomer model, monomer model with pTM head, or multimer model." style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <FormControl sx={{
                    margin: '20px 0 10px 0',
                    width: '40%',
                }}>
                    <InputLabel>database</InputLabel>
                    <Select
                        id="db_preset"
                        name="db_preset"
                        defaultValue={'full_dbs'}
                        label="database"
                        size='small'
                    >
                        <MenuItem value={'full_dbs'}>full</MenuItem>
                        <MenuItem value={'reduced_dbs'}>reduced</MenuItem>
                        {/* <MenuItem value={'no_dbs'}>no_dbs</MenuItem> */}
                    </Select>
                </FormControl>
                <LightTooltip title="Choose preset MSA database configuration - smaller genetic database config (reduced_dbs) or full genetic database config  (full_dbs)." style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <TextField
                    margin="dense"
                    id="max_template_date"
                    name="max_template_date"
                    label="max_template_date"
                    type="date"
                    defaultValue='2024-01-01'
                    variant="outlined"
                    size='small'
                    sx={{
                        margin: '5px 0 40px 0',
                        width: '40%',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: '2024-01-01',
                    }}
                />
                <LightTooltip title="Search for the available templates before the date specified by this parameter; this could be used to avoid certain templates during modeling." style={{ color: 'gray', transform: 'translate(10px, 15px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
            </>
        )
    } else if (cardType === 'AF') {
        return <Box sx={{ padding: '10px' }}></Box>
    } else if (cardType === 'ESM') {
        return (
            <>
                <TextField
                    required
                    margin="dense"
                    id="num_recycles"
                    name="num_recycles"
                    label="recycles number"
                    type="number"
                    defaultValue={4}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 40px 0'
                    }}
                    inputProps={{
                        min: 1,
                        max: 1024
                    }}
                />
                <LightTooltip title="Number of recycles to run. Defaults to number used in training (4)." style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
            </>
        )
    } else if (cardType === 'LigandMPNN') {
        return (
            <>
                <FormControl sx={{
                    margin: '20px 0 0 0',
                    width: '40%',
                }}>
                    <InputLabel>model</InputLabel>
                    <Select
                        id="model_type"
                        name="model_type"
                        defaultValue={modelType}
                        label="model"
                        size='small'
                        required
                        onChange={(event) => setModelType(event.target.value)}
                    >
                        <MenuItem value={'protein_mpnn'}>protein_mpnn</MenuItem>
                        <MenuItem value={'ligand_mpnn'}>ligand_mpnn</MenuItem>
                        <MenuItem value={'per_residue_label_membrane_mpnn'}>per_residue_label_membrane_mpnn</MenuItem>
                        <MenuItem value={'global_label_membrane_mpnn'}>global_label_membrane_mpnn</MenuItem>
                        <MenuItem value={'soluble_mpnn'}>soluble_mpnn</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip
                    title={modelType === 'protein_mpnn' ? 'Run ProteinMPNN' :
                        modelType === 'ligand_mpnn' ? 'Run LigandMPNN' :
                            modelType === 'per_residue_label_membrane_mpnn' ? 'Run per residue label membrane MPNN (trained with extra input per residue specifying buried (hydrophobic), interface (polar), or other type residues; 3 classes)' :
                                modelType === 'global_label_membrane_mpnn' ? 'Run global label membrane MPNN (trained with extra input - binary label soluble vs not)' :
                                    modelType === 'soluble_mpnn' ? 'Run SolubleMPNN (ProteinMPNN-like model with only soluble proteins in the training dataset)' : ''}
                    style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <TextField
                    required
                    margin="dense"
                    id="num_sequences"
                    name="num_sequences"
                    label="num_sequences"
                    type="number"
                    defaultValue={1}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 0 0'
                    }}
                    inputProps={{
                        min: 1,
                        max: 1024
                    }}
                />
                <LightTooltip title="Number of sequence to generate" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>


                {
                    modelType === 'protein_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'proteinmpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'proteinmpnn_v_48_002'}>proteinmpnn_v_48_002</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_010'}>proteinmpnn_v_48_010</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_020'}>proteinmpnn_v_48_020</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_030'}>proteinmpnn_v_48_030</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.02A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'ligand_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'ligandmpnn_v_32_010_25'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'ligandmpnn_v_32_005_25'}>ligandmpnn_v_32_005_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_010_25'}>ligandmpnn_v_32_010_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_020_25'}>ligandmpnn_v_32_020_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_030_25'}>ligandmpnn_v_32_030_25</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.05A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'soluble_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'solublempnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'solublempnn_v_48_002'}>solublempnn_v_48_002</MenuItem>
                                <MenuItem value={'solublempnn_v_48_010'}>solublempnn_v_48_010</MenuItem>
                                <MenuItem value={'solublempnn_v_48_020'}>solublempnn_v_48_020</MenuItem>
                                <MenuItem value={'solublempnn_v_48_030'}>solublempnn_v_48_030</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.02A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'global_label_membrane_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'global_label_membrane_mpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'global_label_membrane_mpnn_v_48_020'}>global_label_membrane_mpnn_v_48_020</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.20A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'per_residue_label_membrane_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'per_residue_label_membrane_mpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'per_residue_label_membrane_mpnn_v_48_020'}>per_residue_label_membrane_mpnn_v_48_020</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.20A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                <TextField
                    required
                    margin="dense"
                    id="temperature"
                    name="temperature"
                    label="temperature"
                    type="number"
                    defaultValue={0.05}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 40px 0'
                    }}
                    inputProps={{
                        min: 0.01,
                        step: 0.01
                    }}
                />
                <LightTooltip title="Change sampling temperature (higher temperature gives more sequence diversity)" style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>

                <FormControl sx={{
                    margin: '20px 0 40px 0',
                    width: '40%',
                }}>
                    <InputLabel>save_stats</InputLabel>
                    <Select
                        id="save_stats"
                        name="save_stats"
                        defaultValue={1}
                        label="save_stats"
                        size='small'
                    >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip title="Save sequence design statistics" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
            </>
        );
    } else if (cardType === 'RFAA') {
        return (
            <>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
            </>
        )
    }
}


function CustomDialogContent({
    cardType,
    handleFileUpload,
    handleAddFileByStringOpen,
    handleFileStringChange,
    handleFileStringSubmit,
    handleAddFileByStringSubnameChange,
    removeFile,
    addFileByString,
    addFileByStringSubname,
    addFileByStringOpen,
    addFileByStringError,
    uploadFiles,
    fileName,
    taskpage,
    userInputTable,
    setUserInputTable
}) {
    const [modelType, setModelType] = React.useState('ligand_mpnn');
    const [taskName, setTaskName] = React.useState('');
    const [currentTime, setCurrentTime] = React.useState('')
    const [defaultName, setDefaultName] = React.useState(true);
    const [advancedOpen, setAdvancedOpen] = React.useState(true);
    const [ligandResidueName, setligandResidueName] = React.useState('');
    const [ligandChainID, setligandChainID] = React.useState('');
    const [P_chains, setP_chains] = React.useState('');
    const [P_chains_error_message, setP_chains_error_message] = React.useState('');
    const [D_chains, setD_chains] = React.useState('');
    const [D_chains_error_message, setD_chains_error_message] = React.useState('');
    const [Redesigned_residues, setRedesigned_residues] = React.useState('');
    const [Fixed_residues, setFixed_residues] = React.useState('');
    const [error_message, setError_message] = React.useState('');
    const [Residue_Name, setResidue_Name] = React.useState('');
    const [Residue_Name_error_message, setResidue_Name_error_message] = React.useState('');
    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(true);
    const [checked3, setChecked3] = React.useState(false);
    const [mode, setMode] = React.useState('none');

    React.useEffect(() => {
        const now = new Date();
        const formattedTime = now.toLocaleString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        setTaskName(formattedTime);
        setCurrentTime(formattedTime);
    }, []);

    React.useEffect(() => {
        if (uploadFiles.length > 0 && cardType === 'LigandMPNN') {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                const lines = content.split('\n');
                const pdbLines = lines.filter((line) => line.startsWith('ATOM'));
                const pdbChainID = pdbLines.map((line) => line.slice(21, 22));
                const uniqueChainID = [...new Set(pdbChainID)];
                let pdbData = {};
                uniqueChainID.forEach((chainID) => {
                    pdbData[chainID] = [...new Set(pdbLines
                        .filter((line) => line.slice(21, 22) === chainID)
                        .map((line) => parseInt(line.slice(22, 26))))];
                });
                setligandChainID(Object.keys(pdbData));
                const chainID = Object.keys(pdbData);
                let chainResidueID = [];
                chainID.forEach((chain) => {
                    pdbData[chain].forEach((residueID) => {
                        chainResidueID.push(chain + residueID);
                    }
                    );
                });
                setligandResidueName(chainResidueID);
                let chainIDTXT = ''
                for (let i = 0; i < chainID.length; i++) {
                    chainIDTXT += chainID[i] + ',';
                }
                setP_chains(chainIDTXT.slice(0, -1));
                setD_chains(chainIDTXT.slice(0, -1));
            };
            reader.readAsText(uploadFiles[0]);
        }
        // eslint-disable-next-line
    }, [uploadFiles]);

    function handleTaskNameChange(event) {
        const userInput = event.target.value;
        if (defaultName && userInput.includes(currentTime)) {
            setDefaultName(false);
            const newTaskName = userInput.replace(currentTime, '').trim();
            setTaskName(newTaskName);
        } else {
            setTaskName(userInput);
        }
    }

    function checkPChains(value) {
        const chains = value.split(',').map(item => item.trim());
        const chainSet = new Set(chains);
        console.log(chains)
        if (!value) {
            setP_chains_error_message('');
        } else if (chainSet.size !== chains.length) {
            setP_chains_error_message('Duplicate chain ID');
        } else if (chains.some(chain => !ligandChainID.includes(chain))) {
            setP_chains_error_message('Invalid chain ID');
        } else {
            setP_chains_error_message('');
        }
    }

    function checkDChains(value) {
        const chains = value.split(',').map(item => item.trim());
        const chainSet = new Set(chains);
        const pchain = P_chains.split(',').map(item => item.trim());
        if (!value) {
            setD_chains_error_message('');
        } else if (chainSet.size !== chains.length) {
            setD_chains_error_message('Duplicate chain ID');
        } else if (chains.some(chain => !pchain.includes(chain))) {
            setD_chains_error_message('Invalid chain ID');
        } else {
            setD_chains_error_message('');
        }
    }

    function checkRedesignedResidues(value) {
        const residues = value.split(',').map(item => item.trim());
        const residueSet = new Set(residues);
        const dchain = D_chains.split(',').map(item => item.trim());
        if (!value) {
            setError_message('');
        } else if (residueSet.size !== residues.length) {
            setError_message('Duplicate residue ID');
        } else if (residues.some(residue => !dchain.includes(residue[0])) || residues.some(residue => !ligandResidueName.includes(residue))) {
            setError_message('Invalid residue ID');
        } else {
            setError_message('');
        }
    }

    function checkFixedResidues(value) {
        const residues = value.split(',').map(item => item.trim());
        const residueSet = new Set(residues);
        const dchain = D_chains.split(',').map(item => item.trim());
        if (!value) {
            setError_message('');
        } else if (residueSet.size !== residues.length) {
            setError_message('Duplicate residue ID');
        } else if (residues.some(residue => !dchain.includes(residue[0])) || residues.some(residue => !ligandResidueName.includes(residue))) {
            setError_message('Invalid residue ID');
        } else {
            setError_message('');
        }
    }

    function checkResidueName(value) {
        const residue = value.split(',').map(item => item.trim());
        const residueSet = new Set(residue);
        const dchain = D_chains.split(',').map(item => item.trim());
        const rr = Redesigned_residues.split(',').map(item => item.trim());
        const fr = Fixed_residues.split(',').map(item => item.trim());
        if (!value) {
            setResidue_Name_error_message('Please input residue ID');
        } else if (residueSet.size !== 1) {
            setResidue_Name_error_message('Only one residue ID is allowed');
        } else {
            if (residue.some(residue => !ligandResidueName.includes(residue))) {
                setResidue_Name_error_message('Invalid residue ID');
                return false;
            } else {
                if (Redesigned_residues === '' && Fixed_residues === '') {
                    if (residue.some(residue => !dchain.includes(residue[0]))) {
                        setResidue_Name_error_message('Invalid residue ID');
                        return false;
                    } else {
                        setResidue_Name_error_message('');
                        return true
                    }
                } else if (Redesigned_residues === '') {
                    if (residue.some(residue => fr.includes(residue))) {
                        setResidue_Name_error_message('Invalid residue ID');
                        return false;
                    } else {
                        setResidue_Name_error_message('');
                        return true
                    }
                } else if (Fixed_residues === '') {
                    if (residue.some(residue => !rr.includes(residue))) {
                        setResidue_Name_error_message('Invalid residue ID');
                        return false;
                    } else {
                        setResidue_Name_error_message('');
                        return true
                    }
                }
            }
            setResidue_Name_error_message('');
            return true;
        }
        return false;
    }

    function handleUserInputTableAdd(event) {
        event.preventDefault();
        const protein_letters_1to3 = { "A": "ALA", "C": "CYS", "D": "ASP", "E": "GLU", "F": "PHE", "G": "GLY", "H": "HIS", "I": "ILE", "K": "LYS", "L": "LEU", "M": "MET", "N": "ASN", "P": "PRO", "Q": "GLN", "R": "ARG", "S": "SER", "T": "THR", "V": "VAL", "W": "TRP", "Y": "TYR" }
        const name = document.getElementById('Residue_Name').value;
        let value = {};
        if (checkResidueName(name)) {
            for (const [key, _value] of Object.entries(protein_letters_1to3)) {
                value[key] = parseFloat(document.getElementById('user_input' + _value).value);
            }
            const filteredTable = userInputTable.filter(item => item.name !== name);
            setUserInputTable([...filteredTable, { name: name, value: value }]);
        }
    }

    function handleModeChange(event) {
        setMode(event.target.value);
        setRedesigned_residues('');
        setFixed_residues('');
        setError_message('');
    }

    return (
        <Box>

            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    margin: '20px 0 0 0',
                }}
            >
                <Box
                    width={1 / 2}
                >
                    <Box
                        component={'div'}
                        sx={{
                            fontSize: '24px',
                            fontWeight: '600',
                            color: '#000000',
                            textAlign: 'left',
                            margin: '20px 0 20px 0',
                        }}
                    >
                        1. Primary Settings
                    </Box>
                    <Box component={'div'}>
                        <TextField
                            required
                            margin="dense"
                            id="task_name"
                            name="task_name"
                            label="Task Name"
                            type="text"
                            variant="outlined"
                            value={taskName}
                            onChange={handleTaskNameChange}
                            sx={{
                                width: '55%'
                            }}
                        />
                    </Box>
                    <CustomCardChoice cardType={cardType} modelType={modelType} setModelType={setModelType} />
                </Box>
                <Box
                    width={1 / 2}
                >
                    <Box
                        component={'div'}
                        sx={{
                            fontSize: '24px',
                            fontWeight: '600',
                            color: '#000000',
                            textAlign: 'left',
                            margin: '20px 0 20px 0',
                        }}
                    >
                        2. Upload Your Files
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            border: '1.5px dashed #517DFF',
                            borderRadius: '7px',
                            height: '150px',
                            backgroundColor: '#E5EEF89E',
                            position: 'relative',
                            cursor: 'pointer',
                        }}
                        component="label"
                    >
                        <CloudUploadIcon sx={{ color: '#517DFF', fontSize: 40 }} />
                        <Box component={'span'} sx={{ color: '#517DFF', fontSize: '24px' }}>
                            {cardType === 'LigandMPNN' ? "Upload File" : "Upload File(s)"}
                        </Box>
                        <Box component={'span'} sx={{ color: '#AAAAAA', fontSize: '18px' }}>
                            Upload one or more
                            {cardType === 'LigandMPNN' ? '*.pdb ' : '*.fa, *.fasta '}
                            files to create a task.
                        </Box>
                        <VisuallyHiddenInput
                            type="file"
                            multiple={cardType === 'LigandMPNN' ? false : true}
                            accept={cardType === 'LigandMPNN' ? '.pdb' : '.fa, .fasta'}
                            onChange={handleFileUpload}
                        />
                    </Box>
                    <Box component={'div'} id='file_name' sx={{
                        margin: '10px 0 10px 0',
                    }}>
                        {fileName.map((name, index) => (
                            <Box component={'div'} key={index} style={{
                                borderRadius: '2px',
                                backgroundColor: name === 'Please select at least one file' ? 'transparent' : 'transparent',
                                margin: '5px 10px 5px 0',
                                color: name === 'Please select at least one file' ? 'red' : '#517DFF',
                                padding: '0px 3px',
                                display: 'inline-block',
                            }}>
                                <InsertDriveFileIcon fontSize='small' sx={{ transform: 'translateY(3px)' }} />
                                {name}
                                <IconButton
                                    sx={{ color: '#517DFF' }}
                                    onClick={removeFile(index)}
                                    size='small'
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    {
                        cardType === 'LigandMPNN' ? <></> :
                            <Box component={'div'} id='add_file_by_string' sx={{ position: 'relative' }}>
                                <Box component={'div'} sx={{ fontSize: '14px', marginTop: '30px', textAlign: 'center', color: '#9F9F9F' }}>
                                    Or upload from Sequence Content
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        error={addFileByStringError}
                                        id="sequence_content"
                                        label="sequence content"
                                        multiline
                                        fullWidth
                                        minRows={2}
                                        variant="outlined"
                                        size='small'
                                        // eslint-disable-next-line
                                        placeholder={">6D0T_1|Chains A, B|BB1|synthetic construct (32630)" + '\n' + "MVDAAQYFPGTWEFRFRS..."}
                                        value={addFileByString}
                                        onChange={handleFileStringChange}
                                        sx={{ marginTop: '20px' }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        label="Sequence Name"
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        value={addFileByStringSubname}
                                        onChange={handleAddFileByStringSubnameChange}
                                        inputProps={{ maxLength: 20 }}
                                    />
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary" size='small'
                                    onClick={handleFileStringSubmit}
                                    disabled={addFileByString === ''}
                                    sx={{ position: 'absolute', bottom: '5px', right: '3px' }}
                                >
                                    Add sequence
                                </Button>
                            </Box>

                    }
                </Box>

            </Box>
            {
                cardType === 'LigandMPNN' && uploadFiles.length > 0 ?
                    <Box
                    >
                        <Button
                            variant="text"
                            endIcon={advancedOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            onClick={() => { setAdvancedOpen(!advancedOpen) }}
                        >
                            *Advanced Settings (Optional)
                        </Button>
                        {
                            advancedOpen ?
                                <>
                                    <br />
                                    <TextField
                                        margin="dense"
                                        id="P_chains"
                                        name="parse_these_chains_only"
                                        label="Visible chains"
                                        variant="outlined"
                                        size='small'
                                        helperText={P_chains_error_message}
                                        error={P_chains_error_message !== ''}
                                        sx={{
                                            width: '20%',
                                            margin: '20px 20px 0px 0'
                                        }}
                                        value={P_chains}
                                        onChange={(event) => setP_chains(event.target.value)}
                                        onBlur={(event) => checkPChains(event.target.value)}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="D_chains"
                                        name="chains_to_design"
                                        label="Chains to design"
                                        variant="outlined"
                                        size='small'
                                        helperText={D_chains_error_message}
                                        error={D_chains_error_message !== ''}
                                        sx={{
                                            width: '20%',
                                            margin: '20px 20px 0px 0'
                                        }}
                                        value={D_chains}
                                        onChange={(event) => setD_chains(event.target.value)}
                                        onBlur={(event) => checkDChains(event.target.value)}
                                    />
                                    <FormControl sx={{
                                        margin: '20px 20px 0px 0',
                                        width: '20%',
                                    }}>
                                        <InputLabel>Mode</InputLabel>
                                        <Select
                                            id="Mode"
                                            name="Mode"
                                            defaultValue={'none'}
                                            label="Mode"
                                            size='small'
                                            onChange={handleModeChange}
                                        >
                                            <MenuItem value={'none'}>None</MenuItem>
                                            <MenuItem value={'redesigned_residues'}>Residues to design</MenuItem>
                                            <MenuItem value={'fixed_residues'}>Residues to fix</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        margin="dense"
                                        id="Residues"
                                        name="Residues"
                                        label="Residues"
                                        variant="outlined"
                                        size='small'
                                        helperText={error_message}
                                        error={error_message !== ''}
                                        disabled={mode === 'none'}
                                        sx={{
                                            width: '20%',
                                            margin: '20px 20px 10px 0'
                                        }}
                                        value={mode === 'redesigned_residues' ? Redesigned_residues : mode === 'fixed_residues' ? Fixed_residues : ''}
                                        onChange={(event) => mode === 'redesigned_residues' ? setRedesigned_residues(event.target.value) : mode === 'fixed_residues' ? setFixed_residues(event.target.value) : ''}
                                        onBlur={(event) => mode === 'redesigned_residues' ? checkRedesignedResidues(event.target.value) : mode === 'fixed_residues' ? checkFixedResidues(event.target.value) : ''}
                                    />
                                    <br />
                                    <FormControlLabel
                                        value={checked1}
                                        control={
                                            <IOSSwitch
                                                checked={checked1}
                                                onChange={(event) => {
                                                    setChecked1(event.target.checked);
                                                }}
                                                color="primary"
                                                sx={{ marginLeft: '15px' }}
                                            />
                                        }
                                        label="Homo oligomer"
                                        name="homo_oligomer"
                                        labelPlacement="start"
                                        sx={{ marginLeft: '0px' }}
                                    />
                                    {
                                        modelType === 'ligand_mpnn' &&
                                        <>
                                            <FormControlLabel
                                                value={checked2}
                                                control={
                                                    <IOSSwitch
                                                        checked={checked2}
                                                        onChange={(event) => {
                                                            setChecked2(event.target.checked);
                                                        }}
                                                        color="primary"
                                                        sx={{ marginLeft: '15px' }}
                                                    />
                                                }
                                                label="Use ligand atom context"
                                                name="ligand_mpnn_use_atom_context"
                                                labelPlacement="start"
                                                sx={{ marginLeft: '60px' }}
                                            />
                                            <FormControlLabel
                                                value={checked3}
                                                control={
                                                    <IOSSwitch
                                                        checked={checked3}
                                                        onChange={(event) => {
                                                            setChecked3(event.target.checked);
                                                        }}
                                                        color="primary"
                                                        sx={{ marginLeft: '15px' }}
                                                    />
                                                }
                                                label="Use side chain context"
                                                name="ligand_mpnn_use_side_chain_context"
                                                labelPlacement="start"
                                                sx={{ marginLeft: '60px' }}
                                            />


                                            <br />
                                            <FormControl>
                                                <TextField
                                                    id="Cutoff_around_ligand"
                                                    name="Cutoff_around_ligand"
                                                    label="Cutoff around ligand (Å)"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    sx={{ margin: '20px 0 10px 0' }}
                                                    inputProps={{ step: "0.01" }} // 这会允许输入浮点数，步长为0.01
                                                    defaultValue={6.0}
                                                    onBlur={(event) => { const value = parseFloat(event.target.value); if (value <= 0) event.target.value = 0.01; }}
                                                />
                                            </FormControl>
                                        </>
                                    }

                                    <Box
                                        sx={{
                                            padding: '8.5px 14px',
                                            borderRadius: '4px',
                                            border: '1px solid #bcbcbc',
                                            width: '100%',
                                            margin: '20px 0 0 0',
                                        }}
                                    >
                                        <Box sx={{ color: '#5E5E5E' }}>Global amino acid bias</Box>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', maxWidth: '800px' }}>
                                            <WeightNumberInput label='ALA' type='acid_bias' />
                                            <WeightNumberInput label='ARG' type='acid_bias' />
                                            <WeightNumberInput label='ASN' type='acid_bias' />
                                            <WeightNumberInput label='ASP' type='acid_bias' />
                                            <WeightNumberInput label='CYS' type='acid_bias' />
                                            <WeightNumberInput label='GLN' type='acid_bias' />
                                            <WeightNumberInput label='GLU' type='acid_bias' />
                                            <WeightNumberInput label='GLY' type='acid_bias' />
                                            <WeightNumberInput label='HIS' type='acid_bias' />
                                            <WeightNumberInput label='ILE' type='acid_bias' />
                                            <WeightNumberInput label='LEU' type='acid_bias' />
                                            <WeightNumberInput label='LYS' type='acid_bias' />
                                            <WeightNumberInput label='MET' type='acid_bias' />
                                            <WeightNumberInput label='PHE' type='acid_bias' />
                                            <WeightNumberInput label='PRO' type='acid_bias' />
                                            <WeightNumberInput label='SER' type='acid_bias' />
                                            <WeightNumberInput label='THR' type='acid_bias' />
                                            <WeightNumberInput label='TRP' type='acid_bias' />
                                            <WeightNumberInput label='TYR' type='acid_bias' />
                                            <WeightNumberInput label='VAL' type='acid_bias' />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '8.5px 14px',
                                            borderRadius: '4px',
                                            border: '1px solid #bcbcbc',
                                            width: '100%',
                                            margin: '20px 0 0 0',
                                        }}
                                    >
                                        <Box sx={{ color: '#5E5E5E' }}>Per residue amino acid bias</Box>
                                        <TextField
                                            margin="dense"
                                            id="Residue_Name"
                                            label="Residue name"
                                            variant="outlined"
                                            size='small'
                                            helperText={Residue_Name_error_message}
                                            error={Residue_Name_error_message !== ''}
                                            sx={{
                                                width: '20%',
                                                margin: '20px 0 10px 0'
                                            }}
                                            value={Residue_Name}
                                            onChange={(event) => setResidue_Name(event.target.value)}
                                            onBlur={(event) => checkResidueName(event.target.value)}
                                        />
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', maxWidth: '800px' }}>
                                            <WeightNumberInput label='ALA' type='user_input' />
                                            <WeightNumberInput label='ARG' type='user_input' />
                                            <WeightNumberInput label='ASN' type='user_input' />
                                            <WeightNumberInput label='ASP' type='user_input' />
                                            <WeightNumberInput label='CYS' type='user_input' />
                                            <WeightNumberInput label='GLN' type='user_input' />
                                            <WeightNumberInput label='GLU' type='user_input' />
                                            <WeightNumberInput label='GLY' type='user_input' />
                                            <WeightNumberInput label='HIS' type='user_input' />
                                            <WeightNumberInput label='ILE' type='user_input' />
                                            <WeightNumberInput label='LEU' type='user_input' />
                                            <WeightNumberInput label='LYS' type='user_input' />
                                            <WeightNumberInput label='MET' type='user_input' />
                                            <WeightNumberInput label='PHE' type='user_input' />
                                            <WeightNumberInput label='PRO' type='user_input' />
                                            <WeightNumberInput label='SER' type='user_input' />
                                            <WeightNumberInput label='THR' type='user_input' />
                                            <WeightNumberInput label='TRP' type='user_input' />
                                            <WeightNumberInput label='TYR' type='user_input' />
                                            <WeightNumberInput label='VAL' type='user_input' />
                                        </Box>

                                        <Box sx={{ textAlign: 'center', margin: '20px 0 0 0' }}>
                                            <Button variant='contained' sx={{ backgroundColor: '#517DFF' }}
                                                onClick={handleUserInputTableAdd}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                        <Divider sx={{margin: '20px 0'}}/>
                                        <UserInputTable table={userInputTable} setTable={setUserInputTable} />
                                    </Box>
                                </>
                                :
                                <>
                                    <Box sx={{ margin: '40px 0 0 0' }}></Box>
                                </>
                        }
                    </Box>
                    : <></>
            }
        </Box>
    )
}

export default function UploadPage({
    task_list,
    updateTaskList,
    cardType,
    close,
    setCountSuccess,
    setCountFail,
    setMessageType,
    setMessageOpen
}) {
    const [fileName, setFileName] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    // const [messageOpen, setMessageOpen] = React.useState(false);
    // const [messageType, setMessageType] = React.useState('success');
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    // const [countSuccess, setCountSuccess] = React.useState(0);
    // const [countFail, setCountFail] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [addFileByString, setAddFileByString] = React.useState("");
    const [addFileByStringSubname, setAddFileByStringSubname] = React.useState("Untitled");
    const [addFileByStringOpen, setAddFileByStringOpen] = React.useState(false);
    const [addFileByStringError, setAddFileByStringError] = React.useState(false);
    const [split, setSplit] = React.useState(false);
    const [userInputTable, setUserInputTable] = React.useState([]);

    const handleClose = () => {
        // setOpen(false);
        close();
    };

    const handleFileUpload = (event) => {
        if (cardType === 'LigandMPNN') {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([...files]);
            setFileName([...fileNames]);
            event.target.value = null;
        } else {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([...uploadFiles, ...files]);
            setFileName([...fileName, ...fileNames]);
            event.target.value = null;
        }
    };

    const handleErrorMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessageOpen(false);
    };

    function handleFileStringChange(event) {
        setAddFileByString(event.target.value);
    }

    function handleFileStringSubmit(event) {
        event.preventDefault();
        if (addFileByString === '') {
            setAddFileByStringError(true);
            return;
        } else if (addFileByStringSubname === '') {
            setAddFileByStringSubname('Untitled');
        }
        const file = new File([addFileByString], addFileByStringSubname + '.fasta', { type: 'text/plain' });
        setUploadFiles([...uploadFiles, file]);
        setFileName([...fileName, addFileByStringSubname]);
        setAddFileByString('');
        setAddFileByStringSubname('Untitled');
        setAddFileByStringOpen(false);
    }

    function handleAddFileByStringOpen() {
        setAddFileByStringOpen(!addFileByStringOpen);
    }

    function handleAddFileByStringSubnameChange(event) {
        setAddFileByStringSubname(event.target.value);
    }

    function clearFile() {
        setFileName([]);
        setUploadFiles([]);
    }

    function removeFile(index) {
        return function () {
            const newFileName = fileName.slice();
            newFileName.splice(index, 1);
            setFileName(newFileName);
            const newUploadFiles = uploadFiles.slice();
            newUploadFiles.splice(index, 1);
            setUploadFiles(newUploadFiles);
        }
    }

    function handleSplitChange(event) {
        setSplit(event.target.checked);
    }

    async function createTask(event) {
        console.log('create task')
        setLoading(true);
        event.preventDefault();
        if (uploadFiles.length === 0) {
            setErrorMessage('Please select at least one file');
            setErrorMessageOpen(true);
            setLoading(false);
            return;
        } else if (localStorage.getItem('token') === null) {
            window.location.href = '/login';
            setLoading(false);
            return;
        }
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if (task_list.includes(formJson.task_name)) {
            setErrorMessage('The task name has been occupied!');
            setErrorMessageOpen(true);
            setLoading(false);
            return
        }
        let count_success = 0;
        let count_fail = 0;
        for (let i = 0; i < uploadFiles.length; i++) {
            const message = new FormData();
            for (const [key, value] of Object.entries(formJson)) {
                message.append(key, value);
            }
            if (cardType === 'AFM') {
                message.append('model', 'alphafold2');
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'AF') {
                message.append('model', 'alphafold2_single')
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'ESM') {
                message.append('model', 'esmfold')
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'LigandMPNN') {
                message.append('model', 'ligandmpnn')
                message.append('file', uploadFiles[i]);
                const protein_letters_1to3 = { "A": "ALA", "C": "CYS", "D": "ASP", "E": "GLU", "F": "PHE", "G": "GLY", "H": "HIS", "I": "ILE", "K": "LYS", "L": "LEU", "M": "MET", "N": "ASN", "P": "PRO", "Q": "GLN", "R": "ARG", "S": "SER", "T": "THR", "V": "VAL", "W": "TRP", "Y": "TYR" }
                let bias_AA = {}
                for (const [key, value] of Object.entries(protein_letters_1to3)) {
                    bias_AA[key] = Object.fromEntries(message.entries())['acid_bias' + value];
                }
                let bias_AA_per_residue = {};
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(userInputTable)) {
                    bias_AA_per_residue[value.name] = value.value;
                }
                message.append('bias_AA', JSON.stringify(bias_AA));
                message.append('bias_AA_per_residue', JSON.stringify(bias_AA_per_residue));
            }
            message.append('index', i);
            // console.log(Object.fromEntries(message.entries()))
            // return
            try {
                let url = '';
                if (cardType === 'LigandMPNN') {
                    url = `${process.env.REACT_APP_API_URL}/api/create_mpnn_task`;
                } else {
                    url = `${process.env.REACT_APP_API_URL}/api/create_structure_prediction_task_fused`;
                    if (split) {
                        message.append('split_file', '1')
                    } else {
                        message.append('split_file', '0')
                    }
                }
                const response = await fetch(url, {
                    method: 'POST',
                    body: message,
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                    }
                });
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else if (response.status === 400 || response.status === 500) {
                    setMessageType('error');
                    console.log(response);
                } else {
                    if (cardType === 'LigandMPNN') {
                        if (response.status === 200) {
                            count_success += 1;
                        } else {
                            count_fail += 1;
                        }
                    } else {
                        console.log(response)
                        const result = await response.json();
                        console.log('Success:', result);
                        count_success += result['success'];
                        count_fail += result['failed'];
                        setMessageType('success');
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if (count_success === 0) {
            setMessageType('error');
        } else {
            setMessageType('success');
        }
        handleClose();
        setCountSuccess(count_success);
        setCountFail(count_fail);
        setMessageOpen(true);
        setLoading(false);
        updateTaskList();
        clearFile();
    }

    return (
        <Box>
            <Box sx={{ position: 'fixed', bottom: '0', left: '0', height: 'calc(100vh - 200px)', width: '100vw', backgroundColor: 'rgba(245, 245, 245, 1)', zIndex: '-1' }}></Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    sx={{ width: '1200px' }}
                    onClose={handleClose}
                    component={'form'}
                    onSubmit={createTask}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                        }
                    }}
                >
                    <Box sx={{ fontSize: '32.59px', padding: '32px', lineHeight: '1.3' }}>
                        <Box
                            component={'span'}
                            sx={{
                                color: '#919191',
                                fontSize: '23.63px'
                            }}
                        >
                            You have chosen
                            <Box
                                component={'span'}
                                sx={{
                                    color: '#000000',
                                    fontSize: '23.63px'
                                }}
                            >
                                {
                                    cardType === 'AFM' ? ' AlphaFold2' :
                                        cardType === 'ESM' ? ' ESMFold' :
                                            cardType === 'LigandMPNN' ? ' LigandMPNN' : ''
                                }
                            </Box>
                        </Box>
                    </Box>
                    <CustomDialogContentText cardType={cardType} />

                    <CustomDialogContent
                        cardType={cardType}
                        handleFileUpload={handleFileUpload}
                        handleAddFileByStringOpen={handleAddFileByStringOpen}
                        handleFileStringChange={handleFileStringChange}
                        handleFileStringSubmit={handleFileStringSubmit}
                        handleAddFileByStringSubnameChange={handleAddFileByStringSubnameChange}
                        removeFile={removeFile}
                        addFileByString={addFileByString}
                        addFileByStringSubname={addFileByStringSubname}
                        addFileByStringOpen={addFileByStringOpen}
                        addFileByStringError={addFileByStringError}
                        uploadFiles={uploadFiles}
                        fileName={fileName}
                        taskpage={false}
                        userInputTable={userInputTable}
                        setUserInputTable={setUserInputTable}
                    />
                    {
                        cardType !== 'LigandMPNN' &&
                        <Box>
                            <Checkbox checked={split} onChange={handleSplitChange} />
                            Split sequences in one fasta file
                            <LightTooltip title="Split the fasta file into multiple tasks, each containing one chain. (each sequence is seen as a monomer)" style={{ color: 'gray', transform: 'translate(10px, 4px)' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    }
                    <DialogActions>
                        <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                        <Button type="submit" variant='contained'
                            sx={{
                                backgroundColor: '#517DFF',
                                ':hover': {
                                    backgroundColor: '#3C6AC8',
                                }
                            }}
                        >Create</Button>
                    </DialogActions>
                    <Snackbar open={errorMessageOpen} autoHideDuration={6000} onClose={handleErrorMessageClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert
                            onClose={handleErrorMessageClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </Box>
        </Box>
    );
}