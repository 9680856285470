import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import FilePresentIcon from '@mui/icons-material/FilePresent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Add from '@mui/icons-material/Add';
// import Divider from '@mui/material/Divider';
// import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
// import RemoveIcon from '@mui/icons-material/Remove';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AF from './img/AF.jpg';
import AFM from './img/AFM.jpg';
import ESM from './img/ESM.jpg';
import LIGANDMPNN from './img/LigandMPNN.jpg';
import RFAA from './img/RFAA.png';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


function CustomCardContent({ cardType }) {
    if (cardType === 'AF') {
        return (
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    AlphaFold2(-Multimer)
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: '50px', overflow: 'auto' }}>
                    Accurately predict protein structure and even protein-protein complex with MSA database.
                </Typography>
            </CardContent>
        );
    } else if (cardType === 'AFM') {
        return (
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    AlphaFold2-Single
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: '50px', overflow: 'auto' }}>
                    A fast verison of AlphaFold2 for single protein structure prediction without using MSA database.
                </Typography>
            </CardContent>
        );
    } else if (cardType === 'ESM') {
        return (
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    ESMFold
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: '50px', overflow: 'auto' }}>
                    Protein language model-based 3D structure prediction at the atomic level.
                </Typography>
            </CardContent>
        );
    } else if (cardType === 'LigandMPNN') {
        return (
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    LigandMPNN
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: '50px', overflow: 'auto' }}>
                    Deep learning-based protein sequence design method that allows explicit modeling of small molecule, nucleotide, metal, and other atomic contexts
                </Typography>
            </CardContent>
        );
    } else if (cardType === 'RFAA') {
        return (
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Rosetta FoldFromAminoAcid
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: '50px', overflow: 'auto' }}>
                    A protein structure prediction method based on Rosetta.
                </Typography>
            </CardContent>
        );
    }
}


function CustomDialogContentText({ cardType }) {
    if (cardType === 'AF') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', color: '#517DFF', background: '#EFF4FB', padding: '10px' }}>
                Current version: <b>2.3.2.</b> Upload one or more <b>*.fasta, *.fa</b> files to create a task. To predict protein complex's structure, please select multimer.
            </DialogContentText>
        );
    } else if (cardType === 'AFM') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', color: '#517DFF', background: '#EFF4FB', padding: '10px' }}>
                Current version: <b>2.2.3.</b> Upload one or more <b>*.fasta, *.fa</b> files to create a task.
            </DialogContentText>
        );
    } else if (cardType === 'ESM') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', color: '#517DFF', background: '#EFF4FB', padding: '10px' }}>
                Upload one or more <b>*.fasta, *.fa</b> files to create a task. num_recycles is a parameter to control the number of iterations. The default value is 4.
            </DialogContentText>
        );
    } else if (cardType === 'LigandMPNN') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', color: '#517DFF', background: '#EFF4FB', padding: '10px' }}>
                Upload one <b>*.pdb</b> file to create a task. LigandMPNN is a deep learning model for protein sequence design.
            </DialogContentText>
        );
    } else if (cardType === 'RFAA') {
        return (
            <DialogContentText sx={{ margin: '0 0 20px 0', color: '#517DFF', background: '#EFF4FB', padding: '10px' }}>
                Any Description?
            </DialogContentText>
        );
    }
}

function CustomCardChoice({ cardType }) {
    const [modelType, setModelType] = React.useState('ligand_mpnn');
    const [advancedOpen, setAdvancedOpen] = React.useState(false);

    if (cardType === 'AF') {
        return (
            <>
                <FormControl sx={{
                    margin: '20px 0 10px 0',
                    width: '40%',
                }}>
                    <InputLabel>model</InputLabel>
                    <Select
                        id="model_preset"
                        name="model_preset"
                        defaultValue={'monomer'}
                        label="model"
                        size='small'
                    >
                        <MenuItem value={'monomer'}>monomer</MenuItem>
                        <MenuItem value={'multimer'}>multimer</MenuItem>
                        <MenuItem value={'monomer_ptm'}>monomer_ptm</MenuItem>
                        <MenuItem value={'monomer_single'}>monomer_single</MenuItem>
                        <MenuItem value={'monomer_ptm_single'}>monomer_ptm_single</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip title="Choose preset model configuration - the monomer model, monomer model with pTM head, or multimer model." style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <FormControl sx={{
                    margin: '20px 0 10px 0',
                    width: '40%',
                }}>
                    <InputLabel>database</InputLabel>
                    <Select
                        id="db_preset"
                        name="db_preset"
                        defaultValue={'full_dbs'}
                        label="database"
                        size='small'
                    >
                        <MenuItem value={'full_dbs'}>full</MenuItem>
                        <MenuItem value={'reduced_dbs'}>reduced</MenuItem>
                        {/* <MenuItem value={'no_dbs'}>no_dbs</MenuItem> */}
                    </Select>
                </FormControl>
                <LightTooltip title="Choose preset MSA database configuration - smaller genetic database config (reduced_dbs) or full genetic database config  (full_dbs)." style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <TextField
                    margin="dense"
                    id="max_template_date"
                    name="max_template_date"
                    label="max_template_date"
                    type="date"
                    defaultValue='2024-01-01'
                    variant="outlined"
                    size='small'
                    sx={{
                        margin: '5px 0 40px 0',
                        width: '40%',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: '2024-01-01',
                    }}
                />
                <LightTooltip title="Search for the available templates before the date specified by this parameter; this could be used to avoid certain templates during modeling." style={{ color: 'gray', transform: 'translate(10px, 15px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
            </>
        )
    } else if (cardType === 'AFM') {
        return <Box sx={{ padding: '10px' }}></Box>
    } else if (cardType === 'ESM') {
        return (
            <>
                <TextField
                    required
                    margin="dense"
                    id="num_recycles"
                    name="num_recycles"
                    label="recycles number"
                    type="number"
                    defaultValue={4}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 40px 0'
                    }}
                    inputProps={{
                        min: 1,
                        max: 1024
                    }}
                />
                <LightTooltip title="Number of recycles to run. Defaults to number used in training (4)." style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
            </>
        )
    } else if (cardType === 'LigandMPNN') {
        return (
            <>
                <FormControl sx={{
                    margin: '20px 0 0 0',
                    width: '40%',
                }}>
                    <InputLabel>model</InputLabel>
                    <Select
                        id="model_type"
                        name="model_type"
                        defaultValue={modelType}
                        label="model"
                        size='small'
                        required
                        onChange={(event) => setModelType(event.target.value)}
                    >
                        <MenuItem value={'protein_mpnn'}>protein_mpnn</MenuItem>
                        <MenuItem value={'ligand_mpnn'}>ligand_mpnn</MenuItem>
                        <MenuItem value={'per_residue_label_membrane_mpnn'}>per_residue_label_membrane_mpnn</MenuItem>
                        <MenuItem value={'global_label_membrane_mpnn'}>global_label_membrane_mpnn</MenuItem>
                        <MenuItem value={'soluble_mpnn'}>soluble_mpnn</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip
                    title={modelType === 'protein_mpnn' ? 'Run ProteinMPNN' :
                        modelType === 'ligand_mpnn' ? 'Run LigandMPNN' :
                            modelType === 'per_residue_label_membrane_mpnn' ? 'Run per residue label membrane MPNN (trained with extra input per residue specifying buried (hydrophobic), interface (polar), or other type residues; 3 classes)' :
                                modelType === 'global_label_membrane_mpnn' ? 'Run global label membrane MPNN (trained with extra input - binary label soluble vs not)' :
                                    modelType === 'soluble_mpnn' ? 'Run SolubleMPNN (ProteinMPNN-like model with only soluble proteins in the training dataset)' : ''}
                    style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <TextField
                    required
                    margin="dense"
                    id="num_sequences"
                    name="num_sequences"
                    label="num_sequences"
                    type="number"
                    defaultValue={1}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 0 0'
                    }}
                    inputProps={{
                        min: 1,
                        max: 1024
                    }}
                />
                <LightTooltip title="Number of sequence to generate" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>


                {
                    modelType === 'protein_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'proteinmpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'proteinmpnn_v_48_002'}>proteinmpnn_v_48_002</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_010'}>proteinmpnn_v_48_010</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_020'}>proteinmpnn_v_48_020</MenuItem>
                                <MenuItem value={'proteinmpnn_v_48_030'}>proteinmpnn_v_48_030</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.02A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'ligand_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'ligandmpnn_v_32_010_25'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'ligandmpnn_v_32_005_25'}>ligandmpnn_v_32_005_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_010_25'}>ligandmpnn_v_32_010_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_020_25'}>ligandmpnn_v_32_020_25</MenuItem>
                                <MenuItem value={'ligandmpnn_v_32_030_25'}>ligandmpnn_v_32_030_25</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.05A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'soluble_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'solublempnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'solublempnn_v_48_002'}>solublempnn_v_48_002</MenuItem>
                                <MenuItem value={'solublempnn_v_48_010'}>solublempnn_v_48_010</MenuItem>
                                <MenuItem value={'solublempnn_v_48_020'}>solublempnn_v_48_020</MenuItem>
                                <MenuItem value={'solublempnn_v_48_030'}>solublempnn_v_48_030</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.02A/0.10A/0.20A/0.30A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'global_label_membrane_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'global_label_membrane_mpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'global_label_membrane_mpnn_v_48_020'}>global_label_membrane_mpnn_v_48_020</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.20A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                {
                    modelType === 'per_residue_label_membrane_mpnn' &&
                    <>
                        <FormControl sx={{
                            margin: '20px 0 0px 0',
                            minWidth: '80%',
                        }}>
                            <InputLabel>checkpoint</InputLabel>
                            <Select
                                id="checkpoint"
                                name="checkpoint"
                                defaultValue={'per_residue_label_membrane_mpnn_v_48_020'}
                                label="checkpoint"
                                size='small'
                            >
                                <MenuItem value={'per_residue_label_membrane_mpnn_v_48_020'}>per_residue_label_membrane_mpnn_v_48_020</MenuItem>
                            </Select>
                        </FormControl>
                        <LightTooltip title="noised with 0.20A Gaussian noise" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                            <HelpOutlineIcon fontSize="small" />
                        </LightTooltip>
                    </>
                }
                <TextField
                    required
                    margin="dense"
                    id="temperature"
                    name="temperature"
                    label="temperature"
                    type="number"
                    defaultValue={0.05}
                    variant="outlined"
                    size='small'
                    sx={{
                        width: '40%',
                        margin: '20px 0 40px 0'
                    }}
                    inputProps={{
                        min: 0.01,
                        step: 0.01
                    }}
                />
                <LightTooltip title="Change sampling temperature (higher temperature gives more sequence diversity)" style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>

                <FormControl sx={{
                    margin: '20px 0 40px 0',
                    width: '40%',
                }}>
                    <InputLabel>save_stats</InputLabel>
                    <Select
                        id="save_stats"
                        name="save_stats"
                        defaultValue={1}
                        label="save_stats"
                        size='small'
                    >
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                    </Select>
                </FormControl>
                <LightTooltip title="Save sequence design statistics" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                    <HelpOutlineIcon fontSize="small" />
                </LightTooltip>
                <Button
                    variant="text"
                    endIcon={advancedOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    onClick={() => { setAdvancedOpen(!advancedOpen) }}
                >
                    Advanced Settings
                </Button>
                {
                    advancedOpen ?
                        <>
                            <br />
                            <TextField
                                margin="dense"
                                id="P_chains"
                                name="P_chains"
                                label="P chains"
                                variant="outlined"
                                size='small'
                                sx={{
                                    width: '40%',
                                    margin: '20px 0 0px 0'
                                }}
                            />
                            <LightTooltip title="" style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                            <TextField
                                margin="dense"
                                id="D_chains"
                                name="D_chains"
                                label="D chains"
                                variant="outlined"
                                size='small'
                                sx={{
                                    width: '40%',
                                    margin: '20px 0 0px 0'
                                }}
                            />
                            <LightTooltip title="" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                            <TextField
                                margin="dense"
                                id="R"
                                name="R"
                                label="R"
                                variant="outlined"
                                size='small'
                                sx={{
                                    width: '40%',
                                    margin: '20px 0 10px 0'
                                }}
                            />
                            <LightTooltip title="" style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                            <TextField
                                margin="dense"
                                id="F"
                                name="F"
                                label="F"
                                variant="outlined"
                                size='small'
                                sx={{
                                    width: '40%',
                                    margin: '20px 0 10px 0'
                                }}
                            />
                            <LightTooltip title="" style={{ color: 'gray', transform: 'translate(10px, 30px)' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                            <FormControlLabel
                                value="Homo oligomer"
                                control={<Switch color="primary" />}
                                label="Homo oligomer"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Use ligand atom context"
                                control={<Switch color="primary" />}
                                label="Use ligand atom context"
                                labelPlacement="end"
                                sx={{ marginLeft: '60px' }}
                            />
                            <FormControlLabel
                                value="Use side chain context"
                                control={<Switch color="primary" />}
                                label="Use side chain context"
                                labelPlacement="end"
                            />
                            <br />
                            <TextField
                                margin="dense"
                                id="Cutoff_around_ligand"
                                name="Cutoff_around_ligand"
                                label="Cutoff around ligand"
                                variant="outlined"
                                size='small'
                                sx={{
                                    width: '40%',
                                    margin: '10px 0 10px 0'
                                }}
                            />
                            <LightTooltip title="" style={{ color: 'gray', transform: 'translate(10px, 30px)', marginRight: '40px' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                            <FormControl fullWidth>
                                <InputLabel id="Global_animo_acid_bias">Global animo acid bias</InputLabel>
                                <Select
                                    labelId="Global_animo_acid_bias"
                                    id="Global_animo_acid_bias"
                                    label="Global animo acid bias"
                                    size='small'
                                    sx={{ margin: '10px 0 20px 0' }}
                                >
                                    <MenuItem value={10}>1</MenuItem>
                                    <MenuItem value={20}>2</MenuItem>
                                    <MenuItem value={30}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                        :
                        <>
                            <Box sx={{ margin: '40px 0 0 0' }}></Box>
                        </>
                }
            </>
        );
    } else if (cardType === 'RFAA') {
        return (
            <>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
                <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        border: '1.5px dashed #517DFF',
                        borderRadius: '7px',
                        height: '150px',
                        backgroundColor: '#E5EEF89E',
                    }}
                >
                    <Box component={'span'}>
                        "Upload File(s)"
                    </Box>
                    <VisuallyHiddenInput
                        type="file"
                        multiple={true}
                        accept={'.fa, .fasta'}
                    />
                </Button>
                <Box sx={{ padding: '10px' }}></Box>
            </>
        )
    }
}


function CustomDialogContent({
    cardType,
    handleFileUpload,
    handleAddFileByStringOpen,
    handleFileStringChange,
    handleFileStringSubmit,
    handleAddFileByStringSubnameChange,
    removeFile,
    addFileByString,
    addFileByStringSubname,
    addFileByStringOpen,
    addFileByStringError,
    fileName,
    taskpage,
}) {
    const [taskName, setTaskName] = React.useState('');
    const [currentTime, setCurrentTime] = React.useState('')
    const [defaultName, setDefaultName] = React.useState(true);

    React.useEffect(() => {
        const now = new Date();
        const formattedTime = now.toLocaleString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        setTaskName(formattedTime);
        setCurrentTime(formattedTime);
    }, []);

    function handleTaskNameChange(event) {
        const userInput = event.target.value;
        if (defaultName && userInput.includes(currentTime)) {
            setDefaultName(false);
            const newTaskName = userInput.replace(currentTime, '').trim();
            setTaskName(newTaskName);
        } else {
            setTaskName(userInput);
        }
    }

    return (
        <DialogContent>
            <CustomDialogContentText cardType={cardType} />
            <Box component={'div'}>
                <TextField
                    required
                    margin="dense"
                    id="task_name"
                    name="task_name"
                    label="Task Name"
                    type="text"
                    variant="outlined"
                    value={taskName}
                    onChange={handleTaskNameChange}
                    sx={{
                        width: '55%'
                    }}
                />
            </Box>
            <CustomCardChoice cardType={cardType} />
            {
                taskpage ?
                    <Box component={'div'} id='file_name' sx={{
                        margin: '10px 0 10px 0',
                    }}>
                        {fileName.map((name, index) => (
                            <Box component={'div'} key={index} style={{
                                borderRadius: '2px',
                                backgroundColor: name === 'Please select at least one file' ? 'transparent' : 'transparent',
                                margin: '5px 10px 5px 0',
                                color: name === 'Please select at least one file' ? 'red' : '#517DFF',
                                padding: '0px 3px',
                                display: 'inline-block',
                            }}>
                                <InsertDriveFileIcon fontSize='small' sx={{ transform: 'translateY(3px)' }} />
                                {name}
                            </Box>
                        ))}
                    </Box>
                    :
                    <>
                        <Button
                            fullWidth
                            component="label"
                            variant="outlined"
                            startIcon={<CloudUploadIcon />}
                            sx={{
                                border: '1.5px dashed #517DFF',
                                borderRadius: '7px',
                                height: '150px',
                                backgroundColor: '#E5EEF89E',
                            }}
                        >
                            <Box component={'span'}>
                                {
                                    cardType === 'LigandMPNN' ? "Upload File" : "Upload File(s)"
                                }
                            </Box>
                            <VisuallyHiddenInput
                                type="file"
                                multiple={cardType === 'LigandMPNN' ? false : true}
                                accept={cardType === 'LigandMPNN' ? '.pdb' : '.fa, .fasta'}
                                onChange={handleFileUpload}
                            />
                        </Button>
                        <Box component={'div'} id='file_name' sx={{
                            margin: '10px 0 10px 0',
                        }}>
                            {fileName.map((name, index) => (
                                <Box component={'div'} key={index} style={{
                                    borderRadius: '2px',
                                    backgroundColor: name === 'Please select at least one file' ? 'transparent' : 'transparent',
                                    margin: '5px 10px 5px 0',
                                    color: name === 'Please select at least one file' ? 'red' : '#517DFF',
                                    padding: '0px 3px',
                                    display: 'inline-block',
                                }}>
                                    <InsertDriveFileIcon fontSize='small' sx={{ transform: 'translateY(3px)' }} />
                                    {name}
                                    <IconButton
                                        sx={{ color: '#517DFF' }}
                                        onClick={removeFile(index)}
                                        size='small'
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                        {
                            cardType === 'LigandMPNN' ? <></> :
                                <Box component={'div'} id='add_file_by_string' sx={{ position: 'relative' }}>
                                    <Box component={'div'} sx={{ fontSize: '14px', marginTop: '30px' }}>Or upload from Sequence Content</Box>
                                    <Box mb={2}>
                                        <TextField
                                            error={addFileByStringError}
                                            id="sequence_content"
                                            label="sequence content"
                                            multiline
                                            fullWidth
                                            minRows={2}
                                            variant="outlined"
                                            size='small'
                                            // eslint-disable-next-line
                                            placeholder={">6D0T_1|Chains A, B|BB1|synthetic construct (32630)" + '\n' + "MVDAAQYFPGTWEFRFRS..."}
                                            value={addFileByString}
                                            onChange={handleFileStringChange}
                                            sx={{ marginTop: '20px' }}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            label="Sequence Name"
                                            multiline
                                            fullWidth
                                            variant="outlined"
                                            size='small'
                                            value={addFileByStringSubname}
                                            onChange={handleAddFileByStringSubnameChange}
                                            inputProps={{ maxLength: 20 }}
                                        />
                                    </Box>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary" size='small'
                                        onClick={handleFileStringSubmit}
                                        disabled={addFileByString === ''}
                                        sx={{ position: 'absolute', bottom: '5px', right: '3px' }}
                                    >
                                        Add sequence
                                    </Button>
                                </Box>

                        }
                    </>
            }
        </DialogContent>
    )
}


export function TaskCard({
    task_list,
    updateTaskList,
    liopen,
    lifileName,
    lifileId,
    setLigandNextOpen
}) {
    const [fileName, setFileName] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [messageOpen, setMessageOpen] = React.useState(false);
    const [messageType, setMessageType] = React.useState('success');
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [countSuccess, setCountSuccess] = React.useState(0);
    const [countFail, setCountFail] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [addFileByString, setAddFileByString] = React.useState("");
    const [addFileByStringSubname, setAddFileByStringSubname] = React.useState("Untitled");
    const [addFileByStringOpen, setAddFileByStringOpen] = React.useState(false);
    // eslint-disable-next-line
    const [addFileByStringError, setAddFileByStringError] = React.useState(false);
    const [cardType, setCardType] = React.useState('AF');

    React.useEffect(() => {
        setFileName([lifileName]);
    }, [lifileName, lifileId]);


    const handleFileUpload = (event) => {
        if (cardType === 'LigandMPNN') {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([files]);
            setFileName([fileNames]);
            event.target.value = null;
        } else {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([...uploadFiles, ...files]);
            setFileName([...fileName, ...fileNames]);
            event.target.value = null;
        }
    };

    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageOpen(false);
    };

    const handleErrorMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessageOpen(false);
    };

    function handleFileStringChange(event) {
        setAddFileByString(event.target.value);
    }

    function handleFileStringSubmit(event) {
        event.preventDefault();
        if (addFileByString === '') {
            setAddFileByStringError(true);
            return;
        } else if (addFileByStringSubname === '') {
            setAddFileByStringSubname('Untitled');
        }
        const file = new File([addFileByString], addFileByStringSubname + '.fasta', { type: 'text/plain' });
        setUploadFiles([...uploadFiles, file]);
        setFileName([...fileName, addFileByStringSubname]);
        setAddFileByString('');
        setAddFileByStringSubname('Untitled');
        setAddFileByStringOpen(false);
    }

    function handleAddFileByStringOpen() {
        setAddFileByStringOpen(!addFileByStringOpen);
    }

    function handleAddFileByStringSubnameChange(event) {
        setAddFileByStringSubname(event.target.value);
    }

    function removeFile(index) {
        return function () {
            const newFileName = fileName.slice();
            newFileName.splice(index, 1);
            setFileName(newFileName);
            const newUploadFiles = uploadFiles.slice();
            newUploadFiles.splice(index, 1);
            setUploadFiles(newUploadFiles);
        }
    }


    async function createTask(event) {
        setLoading(true);
        event.preventDefault();
        if (localStorage.getItem('token') === null) {
            window.location.href = '/login';
            setLoading(false);
            return;
        }
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if (task_list.includes(formJson.task_name)) {
            setErrorMessage('The task name has been occupied!');
            setErrorMessageOpen(true);
            setLoading(false);
            return
        }
        let count_success = 0;
        let count_fail = 0;
        const message = new FormData();
        message.append('file_name', lifileName);
        message.append('task_id', lifileId);
        message.append('index', '0');
        for (const [key, value] of Object.entries(formJson)) {
            message.append(key, value);
        }
        if (cardType === 'AF') {
            message.append('model', 'alphafold2');
        } else if (cardType === 'AFM') {
            message.append('model', 'alphafold2_single')
        } else if (cardType === 'ESM') {
            message.append('model', 'esmfold')
        }
        // console.log(Object.fromEntries(message.entries()))
        try {
            let url = '';

            url = `${process.env.REACT_APP_API_URL}/api/create_structure_prediction_task_fused`;
            message.append('split_file', '1')

            const response = await fetch(url, {
                method: 'POST',
                body: message,
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/login';
            } else if (response.status === 400 || response.status === 500) {
                setMessageType('error');
                console.log(response);
            } else {
                console.log(response)
                const result = await response.json();
                console.log('Success:', result);
                count_success += result['success'];
                count_fail += result['failed'];
                setMessageType('success');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        if (count_success === 0) {
            setMessageType('error');
        }
        setLigandNextOpen(false);
        setCountSuccess(count_success);
        setCountFail(count_fail);
        setMessageOpen(true);
        setLoading(false);
        updateTaskList();
    }

    return (
        <Card>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '600px', padding: '32px 32px' } }}
                onClose={() => { setLigandNextOpen(false) }}
                open={liopen}
                PaperProps={{
                    component: 'form',
                    onSubmit: createTask,
                    onClick: (event) => event.stopPropagation()
                }}
            >
                <FormControl sx={{
                    margin: '20px 0 0 0',
                    width: '40%',
                }}>
                    <InputLabel>Choose a model</InputLabel>
                    <Select
                        id="type"
                        name="type"
                        defaultValue={cardType}
                        size='medium'
                        label="Choose a model"
                        required
                        onChange={(event) => setCardType(event.target.value)}
                    >
                        <MenuItem value={'AF'}>alphafold2</MenuItem>
                        <MenuItem value={'AFM'}>alphafold2_single</MenuItem>
                        <MenuItem value={'ESM'}>esmfold</MenuItem>
                    </Select>
                </FormControl>
                <CustomDialogContent
                    cardType={cardType}
                    handleFileUpload={handleFileUpload}
                    handleAddFileByStringOpen={handleAddFileByStringOpen}
                    handleFileStringChange={handleFileStringChange}
                    handleFileStringSubmit={handleFileStringSubmit}
                    handleAddFileByStringSubnameChange={handleAddFileByStringSubnameChange}
                    removeFile={removeFile}
                    fileName={fileName}
                    taskpage={true}
                />
                <DialogActions>
                    <Button onClick={() => { setLigandNextOpen(false) }} variant='outlined'>Cancel</Button>
                    <Button type="submit" variant='contained'>Create</Button>
                </DialogActions>
                <Snackbar open={errorMessageOpen} autoHideDuration={6000} onClose={handleErrorMessageClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleErrorMessageClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
            <Snackbar open={messageOpen} autoHideDuration={6000} onClose={handleMessageClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert
                    onClose={handleMessageClose}
                    severity={messageType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {
                        countSuccess === 0 && countFail === 0 ? 'No task created!' :
                            countSuccess === 0 ? 'All tasks failed to create!' :
                                countFail === 0 ? 'All tasks created successfully!' :
                                    countSuccess + ' Task created successfully! ' + countFail + ' Task failed to create!'
                    }
                </Alert>
            </Snackbar>
        </Card>
    );
}


export default function CardContainer({
    task_list,
    updateTaskList,
    cardType,
    setCountSuccess,
    setCountFail,
    setMessageType,
    setMessageOpen
}) {
    const [fileName, setFileName] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    // const [messageOpen, setMessageOpen] = React.useState(false);
    // const [messageType, setMessageType] = React.useState('success');
    const [errorMessageOpen, setErrorMessageOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    // const [countSuccess, setCountSuccess] = React.useState(0);
    // const [countFail, setCountFail] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [addFileByString, setAddFileByString] = React.useState("");
    const [addFileByStringSubname, setAddFileByStringSubname] = React.useState("Untitled");
    const [addFileByStringOpen, setAddFileByStringOpen] = React.useState(false);
    const [addFileByStringError, setAddFileByStringError] = React.useState(false);
    const [split, setSplit] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileUpload = (event) => {
        if (cardType === 'LigandMPNN') {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([...files]);
            setFileName([...fileNames]);
            event.target.value = null;
        } else {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
            setUploadFiles([...uploadFiles, ...files]);
            setFileName([...fileName, ...fileNames]);
            event.target.value = null;
        }
    };

    const handleErrorMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessageOpen(false);
    };

    function handleFileStringChange(event) {
        setAddFileByString(event.target.value);
    }

    function handleFileStringSubmit(event) {
        event.preventDefault();
        if (addFileByString === '') {
            setAddFileByStringError(true);
            return;
        } else if (addFileByStringSubname === '') {
            setAddFileByStringSubname('Untitled');
        }
        const file = new File([addFileByString], addFileByStringSubname + '.fasta', { type: 'text/plain' });
        setUploadFiles([...uploadFiles, file]);
        setFileName([...fileName, addFileByStringSubname]);
        setAddFileByString('');
        setAddFileByStringSubname('Untitled');
        setAddFileByStringOpen(false);
    }

    function handleAddFileByStringOpen() {
        setAddFileByStringOpen(!addFileByStringOpen);
    }

    function handleAddFileByStringSubnameChange(event) {
        setAddFileByStringSubname(event.target.value);
    }

    function clearFile() {
        setFileName([]);
        setUploadFiles([]);
    }

    function removeFile(index) {
        return function () {
            const newFileName = fileName.slice();
            newFileName.splice(index, 1);
            setFileName(newFileName);
            const newUploadFiles = uploadFiles.slice();
            newUploadFiles.splice(index, 1);
            setUploadFiles(newUploadFiles);
        }
    }

    function handleSplitChange(event) {
        setSplit(event.target.checked);
    }

    async function createTask(event) {
        setLoading(true);
        event.preventDefault();
        if (uploadFiles.length === 0) {
            setErrorMessage('Please select at least one file');
            setErrorMessageOpen(true);
            setLoading(false);
            return;
        } else if (localStorage.getItem('token') === null) {
            window.location.href = '/login';
            setLoading(false);
            return;
        }
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if (task_list.includes(formJson.task_name)) {
            setErrorMessage('The task name has been occupied!');
            setErrorMessageOpen(true);
            setLoading(false);
            return
        }
        let count_success = 0;
        let count_fail = 0;
        for (let i = 0; i < uploadFiles.length; i++) {
            const message = new FormData();
            for (const [key, value] of Object.entries(formJson)) {
                message.append(key, value);
            }
            if (cardType === 'AFM') {
                message.append('model', 'alphafold2');
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'AFM') {
                message.append('model', 'alphafold2_single')
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'ESM') {
                message.append('model', 'esmfold')
                message.append('protein', uploadFiles[i]);
            } else if (cardType === 'LigandMPNN') {
                message.append('model', 'ligandmpnn')
                message.append('file', uploadFiles[i]);
            }
            message.append('index', i);
            // console.log(Object.fromEntries(message.entries()))
            try {
                let url = '';
                if (cardType === 'LigandMPNN') {
                    url = `${process.env.REACT_APP_API_URL}/api/create_mpnn_task`;
                } else {
                    url = `${process.env.REACT_APP_API_URL}/api/create_structure_prediction_task_fused`;
                    if (split) {
                        message.append('split_file', '1')
                    } else {
                        message.append('split_file', '0')
                    }
                }
                const response = await fetch(url, {
                    method: 'POST',
                    body: message,
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                    }
                });
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else if (response.status === 400 || response.status === 500) {
                    setMessageType('error');
                    console.log(response);
                } else {
                    if (cardType === 'LigandMPNN') {
                        if (response.status === 200) {
                            count_success += 1;
                        } else {
                            count_fail += 1;
                        }
                    } else {
                        console.log(response)
                        const result = await response.json();
                        console.log('Success:', result);
                        count_success += result['success'];
                        count_fail += result['failed'];
                        setMessageType('success');
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        if (count_success === 0) {
            setMessageType('error');
        } else {
            setMessageType('success');
        }
        handleClose();
        setCountSuccess(count_success);
        setCountFail(count_fail);
        setMessageOpen(true);
        setLoading(false);
        updateTaskList();
        clearFile();
    }

    return (
        <>
            <Card sx={{
                maxWidth: 345,
                margin: '32px 20px',
                '&:hover': {
                    boxShadow: '0 0 30px rgba(33,33,33,.2)',
                },
            }}>
                <CardActionArea onClick={handleClickOpen}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={cardType === 'AF' ? AF : cardType === 'AFM' ? AFM : cardType === 'ESM' ? ESM : cardType === 'LigandMPNN' ? LIGANDMPNN : RFAA}
                        alt={cardType === 'AF' ? 'AlphaFold2(-Multimer)' : cardType === 'AFM' ? 'AlphaFold2-Single' : cardType === 'ESM' ? 'ESMFold' : cardType === 'LigandMPNN' ? 'LigandMPNN' : 'RFAA'}
                        sx={{
                            borderRadius: '5px 5px 0px 0px',
                        }}
                    />
                    <CustomCardContent cardType={cardType} />
                </CardActionArea>
                <CardActions>
                    <Button size="small" color="primary" onClick={handleClickOpen} fullWidth variant='contained'>
                        Create
                    </Button>
                </CardActions>

                <Dialog
                    sx={{ '& .MuiDialog-paper': { width: '600px', padding: '32px 32px' } }}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: createTask,
                    }}
                >
                    <DialogTitle sx={{ fontSize: '2rem' }}>Create a Task</DialogTitle>
                    <CustomDialogContent
                        cardType={cardType}
                        handleFileUpload={handleFileUpload}
                        handleAddFileByStringOpen={handleAddFileByStringOpen}
                        handleFileStringChange={handleFileStringChange}
                        handleFileStringSubmit={handleFileStringSubmit}
                        handleAddFileByStringSubnameChange={handleAddFileByStringSubnameChange}
                        removeFile={removeFile}
                        addFileByString={addFileByString}
                        addFileByStringSubname={addFileByStringSubname}
                        addFileByStringOpen={addFileByStringOpen}
                        addFileByStringError={addFileByStringError}
                        fileName={fileName}
                        taskpage={false}
                    />
                    {
                        cardType !== 'LigandMPNN' &&
                        <Box>
                            <Checkbox checked={split} onChange={handleSplitChange} />
                            Split sequences in one fasta file
                            <LightTooltip title="Split the fasta file into multiple tasks, each containing one chain. (each sequence is seen as a monomer)" style={{ color: 'gray', transform: 'translate(10px, 4px)' }}>
                                <HelpOutlineIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    }
                    <DialogActions>
                        <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                        <Button type="submit" variant='contained'>Create</Button>
                    </DialogActions>
                    <Snackbar open={errorMessageOpen} autoHideDuration={6000} onClose={handleErrorMessageClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert
                            onClose={handleErrorMessageClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
            </Card>
            {/* <Snackbar
                // open={messageOpen}
                open
                autoHideDuration={6000}
                onClose={handleMessageClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleMessageClose}
                    severity={messageType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {
                        countSuccess === 0 && countFail === 0 ? 'No task created!' :
                            countSuccess === 0 ? 'All tasks failed to create!' :
                                countFail === 0 ? 'All tasks created successfully!' :
                                    countSuccess + ' Task created successfully! ' + countFail + ' Task failed to create!'
                    }
                </Alert>
            </Snackbar> */}
        </>
    );
}